import React from 'react';
import { Box, Typography, Grid, Paper, Container, Card, CardContent } from '@mui/material';

const Choose = () => {
    return (
    <>
        <Box 
            sx={{ 
                padding: 4, 
                textAlign: 'center',
                maxWidth: { xs: '100%', md: '1024px' }, 
                margin: '0 auto',  // Center the Box horizontally
                fontFamily: 'Times New Roman, Times, serif'  // Set font to Times New Roman
            }}
        >
            <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Times New Roman, Times, serif', fontWeight:'bold' }}>
                Why Choose Us?
            </Typography>
        </Box>

        {/* Use Grid to make it responsive */}
        <Container sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },  // 'xs' for small screens, 'sm' for larger screens
        gap: '15px',
        // marginTop: '5px',
        justifyContent: 'center',  // Center the content on smaller screens
      }}>
        
        {/* Agriculture Card */}
        <Card sx={{
          maxWidth: 345,
          "&:hover": {
            color: "#9fafca",
            transition: "all 0.3s ease-in-out",
          }
        }}>
          <CardContent>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            With services like 3D movies, marketing renderings, and quick prototype production, we go above and beyond the norm. We are prepared to meet any needs your project may have.
            </Typography>
          </CardContent>
        </Card>

        {/* Manufacturing Card */}
        <Card sx={{
          maxWidth: 345,
          "&:hover": {
            color: "#9fafca",
            transition: "all 0.3s ease-in-out",
          }
        }}>
          <CardContent>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            To increase your efficiency, our talented software developers can help you automate procedures or modify designs. We offer practical solutions that minimise manual errors and cut down on cycle time.
            </Typography>
          </CardContent>
        </Card>

        {/* Mining Card */}
        <Card sx={{
          maxWidth: 345,
          "&:hover": {
            color: "#9fafca",
            transition: "all 0.3s ease-in-out",
          }
        }}>
          <CardContent>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            We provide a wide range of services that are tailored to your company's particular requirements, such as: Engineering Design Consultancy, Mechanical Engineering Design Services, CAD Draughting Services
            </Typography>
          </CardContent>
        </Card>

      </Container>

      {/* Second Row of Cards */}
      <Container sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },  // Switch to column layout for mobile devices
        gap: '15px',
        marginTop: '15px',
        justifyContent: 'center',
      }}>

        {/* Medical Card */}
        <Card sx={{
          maxWidth: 345,
          "&:hover": {
            color: "#9fafca",
            transition: "all 0.3s ease-in-out",
          }
        }}>
          <CardContent>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Our staff has extensive experience in the manufacturing and design industries. We ensure that your careful draughting, 3D modelling, and designs are completed to the greatest standards. We don't just work for you; we work with your design team.
            </Typography>
          </CardContent>
        </Card>

        {/* Civil Card */}
        <Card sx={{
          maxWidth: 345,
          "&:hover": {
            color: "#9fafca",
            transition: "all 0.3s ease-in-out",
          }
        }}>
          <CardContent>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Do you need someone to manage your projects from beginning to end? We'll take care of you. We are here to make sure your projects are successful, from establishing a PMO governance framework to offering continuing assistance.
            </Typography>
          </CardContent>
        </Card>

        {/* Mechanical Card */}
        <Card sx={{
          maxWidth: 345,
          "&:hover": {
            color: "#9fafca",
            transition: "all 0.3s ease-in-out",
          }
        }}>
          <CardContent>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            We adhere to all Australian standards, codes, and practices because we take compliance seriously. Your designs and intellectual property are secure with us, supported by a Non-Disclosure Agreement and the required insurances.
            </Typography>
          </CardContent>
        </Card>

      </Container>
        
    </>    
    );
};

export default Choose;
