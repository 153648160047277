import React from 'react'
import { Box,  Toolbar, Typography } from '@mui/material';
const Building = () => {
  return (
    <>
         <Box component="main" sx={{ p: 3, bgcolor: '#ede8f5' }}>
        <Toolbar/>
        <Typography variant='h5' sx={{textAlign: 'center', fontWeight: 'bold'}}>BUILDING INFORMATION MODELING</Typography>
        <Typography sx={{textAlign: 'center', marginTop: '15px'}}>
        AspireCoordination Australia Pty Ltd is a leading provider of comprehensive Engineering and IT services. With over 70 years of combined experience, our team excels across a broad array of technology areas and functional domains. Our specialists, drawn from diverse backgrounds including Engineering, IT, Quality, Process Improvement, and Project Management, are well-versed in different industries and committed to upholding the highest quality standards, including strict adherence to Australian Standards. 
        </Typography>
    </Box>
    </>
  )
}   

export default Building
