import React from 'react'
import { Box,  Toolbar, Typography, Container } from '@mui/material';
import image1 from '../assets/3d-render-robots-with-transmission-wheels.jpg'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
const Mechenical = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Box component="main" sx={{ bgcolor: '#ede8f5' }}>
        <Toolbar />
        <Container maxWidth="lg" sx={{ py: 3 }}>
          <Typography variant='h5' sx={{ textAlign: 'center', fontWeight: 'bold' }}>MECHANICAL</Typography>
          <Typography sx={{ textAlign: 'left', marginTop: '15px' }}>
          Our work on operation and maintenance manuals for an industrial machinery manufacturer is a noteworthy success story that received a lot of praise and recognition from the industry.
          </Typography>

          <Typography sx={{ textAlign: 'left', marginTop: '15px' }}>
          Over the years, we have developed a deep understanding of the Mechanical Engineering sector, particularly in Engineering Processes, Procedures, and Standards. Our engineers are well-versed in relevant Australian Standards for the mechanical industry, such as:
            <ul>
              <li><Box component="span" sx={{ fontWeight: 'bold', marginLeft: 1, marginRight: 1 }}>
              AS 1210:
              </Box>  Pressure Vessels</li>
              <li><Box component="span" sx={{ fontWeight: 'bold', marginLeft: 1, marginRight: 1 }}>
              AS 4024:
              </Box> Safety of Machinery</li>
              <li><Box component="span" sx={{ fontWeight: 'bold', marginLeft: 1, marginRight: 1 }}>
              AS/NZS 5601:
              </Box>  Gas Installations</li>
            </ul>
          </Typography>

          {/* <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', marginTop: '30px' }}>
            <Box
              sx={{
                flex: 1,
                height: { xs: 250, md: 400 },
                backgroundImage: `url(${image1})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            />
            <Box sx={{ flex: 2, paddingLeft: { md: 3 }, marginTop: { xs: 2, md: 0 } }}>
              <Typography sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                Engineering Design and Drafting Services
              </Typography>
              <Typography sx={{ textAlign: 'left', marginTop: '5px' }}>
              Our team has substantial experience in designing various components and systems for civil infrastructure, including:
                <ul>
                <li>Enclosures and Casings</li>
                <li>Mechanical Assemblies</li>
                <li>Pressure Vessels</li>
                <li>HVAC Systems</li>
                <li>Structural Frames</li>
                <li>Conveyor Systems</li>
                <li>Hydraulic and Pneumatic Systems</li>
                </ul>
              </Typography>
            </Box>
          </Box> */}

          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            alignItems: 'stretch',
            gap: 4,
            mb: 4
          }}>
            <Box sx={{
              flex: '1 1 50%',
              minHeight: { xs: 300, md: 400 },
              backgroundImage: `url(${image1})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 2,
            }}/>
            <Box sx={{ 
              flex: '1 1 50%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              <Typography sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                Engineering Design and Drafting Services
              </Typography>
              <Typography sx={{ textAlign: 'left', marginTop: '5px' }}>
                Our team has substantial experience in designing various components and systems for mechanical, including:
                <ul>
                <li>Enclosures and Casings</li>
                <li>Mechanical Assemblies</li>
                <li>Pressure Vessels</li>
                <li>HVAC Systems</li>
                <li>Structural Frames</li>
                <li>Conveyor Systems</li>
                <li>Hydraulic and Pneumatic Systems</li>
                </ul>
              </Typography>
            </Box>
          </Box>

          <Typography sx={{ textAlign: 'left', marginTop: '15px' }}>
          Our documentation is meticulously crafted to ensure clarity, usability, and compliance with industry regulations, supporting both the safe operation and maintenance of mechanical equipment.
          </Typography>
        </Container>
      </Box>
    </>
  )
}

export default Mechenical
