import React from 'react'
import { Box, Container, Toolbar, Typography} from '@mui/material';
import image1 from './assets/technologist-with-grey-tablet-his-hands-make-set-up-production-line-while-standing-department-dairy-factory.jpg'
import image3 from './assets/metal-wine-storage-tanks-with-dwelling-houses-background-winery.jpg'
import image4 from './assets/architectural-blueprints.jpg'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';  

const Plant = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Box sx={{ bgcolor: '#ede8f5', width: '100%' }}>
      <Container maxWidth="lg">
        <Box component="main" sx={{ p: 3 }}>
          <Toolbar/>
          <Typography variant='h5' sx={{textAlign: 'center', fontWeight: 'bold', mb: 4}}>PROCESS PLANT DESIGN</Typography>
          <Typography sx={{textAlign: 'left', marginTop: '15px'}}>
          Our designers at Aspire Coordination Australia Pty Ltd have vast backgrounds in Process Plant Design from a variety of fields. We are aware that designing a process plant requires specific expertise and painstaking attention to detail, two things our designers are quite good at. 
          </Typography>

          <Typography sx={{textAlign: 'left', marginTop: '15px'}}>
          We offer specialized design and drafting services for Grain and Meal Material Handling, Processing and Storage. The experience of our staff guarantees that every facet of your plant design is managed with care and accuracy, producing solutions that satisfy the most exacting industry requirements. 
          </Typography>


          {/* First Section: Image and Text */}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            alignItems: 'stretch',
            gap: 4,
            mb: 4,
            marginTop: '15px'
          }}>
            <Box sx={{
              flex: '1 1 50%',
              minHeight: { xs: 300, md: 400 },
              backgroundImage: `url(${image1})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 2,
            }}/>
            <Box sx={{ 
              flex: '1 1 50%', 
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              
              <Typography sx={{ textAlign: 'left', marginTop: '15px' }}>
              Tech Coordination Australia Pty Ltd provides all-inclusive Material Handling & Systems Design solutions that guarantee dependability and efficiency in a range of sectors. In order to satisfy the unique requirements of your business, we have experience designing and putting into place systems including screw conveyors, bucket elevators, drag conveyors, and bucket chain elevators.
              </Typography>
              <Typography sx={{ textAlign: 'left', marginTop: '15px' }}>
              In Storage System Design, we specialize in creating Storage Bins, Liquid Bins, and Large Silos that maximize storage capacity while maintaining safety and ease of access. For Packaging System Design, we offer precise solutions in Weighing, Bagging, and Bulk Bag Stands, ensuring your packaging processes are both efficient and scalable.
              </Typography>
            </Box>
          </Box>

          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            alignItems: 'stretch',
            gap: 4
          }}>
            <Box sx={{ 
              flex: '1 1 50%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              <Typography sx={{ textAlign: 'left', fontWeight:'bold', mb: 2 }}>
              We provide the following types of drawings 
              </Typography>
              <Typography sx={{ textAlign: 'left' }}>
                <ul>
                  <li>Planning drawing</li>
                  <li>Greenfield/brownfield design and drawings</li>
                  <li>Flow diagram</li>
                  <li>Customer requirement and design consideration</li>
                  <li>Civil foundation drawing</li>
                  <li>Acoustic enclosure drawing</li>
                  <li>Electrical drawing</li>
                  <li>Pneumatic drawing</li>
                  <li>General exhaust drawings</li>
                  <li>Platform drawing</li>
                  <li>Access steps and ladder drawings</li>
                </ul>
              </Typography>
            </Box>
            <Box sx={{
              flex: '1 1 50%',
              minHeight: { xs: 300, md: 400 },
              backgroundImage: `url(${image4})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 2,
            }}/>
          </Box>

          {/* Third Section: Image and Text */}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            alignItems: 'stretch',
            gap: 4,
            mb: 4,
            marginTop: '15px'
          }}>
            <Box sx={{
              flex: '1 1 50%',
              minHeight: { xs: 300, md: 400 },
              backgroundImage: `url(${image3})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 2,
            }}/>
            <Box sx={{ 
              flex: '1 1 50%', 
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              
              <Typography sx={{ textAlign: 'left' }}>
              Weigh Hoppers, Micro Ingredient Systems, and full feed mill designs encompassing all phases—pre-cleaning, batching, grinding, mixing, conditioning, pelleting, cooling, crumbling, screening, and bagging—as well as the integration of air supply, water supply, and liquid line are among our processing equipment design capabilities.
              </Typography>

              <Typography sx={{ textAlign: 'left', marginTop: '15px' }}>
              In addition, we offer a broad range of drawing services, such as civil foundation drawings, acoustic enclosure drawings, electrical drawings, pneumatic drawings, general exhaust drawings, platform drawings, flow diagrams, planning drawings, greenfield/brownfield design, customer requirements and design considerations, and drawings for access steps and ladders. Our designs are painstakingly created to satisfy all legal criteria as well as client-specific specifications, guaranteeing smooth project execution from inception to conclusion.
              </Typography>
            </Box>
          </Box>

          
        </Box>
      </Container>
    </Box>
  )
}

export default Plant