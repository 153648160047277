import React from 'react'
import { Box, Container, Toolbar, Typography,TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import image1 from './assets/waibaidu-bridge.jpg'
import image2 from './assets/3d-rendering-ventilation-system.jpg'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';  
const standards = [
    { code: 'AS 1100', description: 'Australian Standard for technical drawing including both mechanical and architectural designs. ' },
    { code: 'AS/NZS 1554', description: 'Structural Steel Welding Set' },
    { code: 'AS 1418', description: 'Cranes, Hoists and Winches' },
    { code: 'AS/NZS 4024.1', description: 'Safety of Machinery' },
    { code: 'AS 1657', description: 'Fixed platform, walkways, stairways and ladders- Design, construct and installation' },
    { code: 'AS 4100', description: 'Steel Structure' },
    { code: 'AS/NZS 5131', description: 'Structural Steelwork- Fabrication and Erection' },
];


const Shop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Box sx={{ bgcolor: '#ede8f5', width: '100%' }}>
      <Container maxWidth="lg">
        <Box component="main" sx={{ p: 3 }}>
          <Toolbar/>
          <Typography variant='h5' sx={{textAlign: 'center', fontWeight: 'bold', mb: 4}}>SHOP DRAWINGS</Typography>
          <Typography sx={{textAlign: 'left', marginTop: '15px'}}>
          Our design team at Aspire Coordination Australia Pty Ltd acts as an essential liaison between structural engineers, architects, and fabricators. We offer comprehensive shop drawings that are prepared for direct manufacturing, guaranteeing smooth teamwork and effective project management.
          </Typography>
          <Typography sx={{textAlign: 'left', fontWeight: 'bold', marginTop: '15px'}}>Precision and Compliance</Typography>
          <Typography sx={{textAlign: 'left', marginTop: '15px'}}>
          Using data from structural engineers and architects, our team painstakingly creates shop drawings, constantly consulting Australian standards to guarantee precision and adherence. These comprehensive drawings serve as a clear and trustworthy blueprint for each project, assisting fabricators in the production and installation of steelwork.
          </Typography>

          {/* Second Section: Text and Image */}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            alignItems: 'stretch',
            gap: 4,
            mb: 4
          }}>
            <Box sx={{ 
              flex: '1 1 50%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
                <Typography sx={{textAlign: 'left', fontWeight: 'bold', marginTop: '15px'}}>Industries We Serve</Typography>
              <Typography sx={{ textAlign: 'left', marginTop: '15px' }}>
              Shop drawings are essential for a number of product businesses, such as
              <ul>
                <li>Elevators</li>
                <li>Structural Steel Trusses</li>
                <li>Windows</li>
                <li>Cabinets</li>
                <li>Air Handling Units</li>
              </ul>
              </Typography>
              <Typography sx={{textAlign: 'left', fontWeight: 'bold', marginTop: '15px'}}>MEP Trades in Construction</Typography>
              <Typography sx={{ textAlign: 'left', marginTop: '15px' }}>
              Our expertise also extends to the MEP (Mechanical, Electrical, and Plumbing) trades, where shop drawings are necessary for:
              <ul>
                <li>Ductwork</li>
                <li>Piping</li>
                <li>Plumbing</li>
                <li>Fire Alarm Systems</li>
                <li>Electrical Systems</li>
                <li>Audio-Visual (AV) Systems</li>
                <li>Public Health Systems</li>
              </ul>
              </Typography>
            </Box>
            <Box sx={{
              flex: '1 1 50%',
              minHeight: { xs: 300, md: 400 },
              backgroundImage: `url(${image1})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 2,
            }}/>
          </Box>

          {/* Third Section: Image and Text */}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            alignItems: 'stretch',
            gap: 4,
            mb: 4
          }}>
            <Box sx={{
              flex: '1 1 50%',
              minHeight: { xs: 300, md: 400 },
              backgroundImage: `url(${image2})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 2,
            }}/>
            <Box sx={{ 
              flex: '1 1 50%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              <Typography  sx={{ textAlign: 'center', marginBottom: '20px' }}>
  We strictly follow Australian Standards in our work. Some of the relevant standards are as below
  </Typography>
  <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell><strong>Code</strong></TableCell>
          <TableCell><strong>Description</strong></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {standards.map((standard, index) => (
          <TableRow key={index}>
            <TableCell>{standard.code}</TableCell>
            <TableCell>{standard.description}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
            </Box>
          </Box>

        </Box>
      </Container>
    </Box>
  )
}

export default Shop