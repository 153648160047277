import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './Component/Header';
import Footer from './Component/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Career from './pages/Career';
import Contact from './pages/Contact';
import Agriculture  from './pages/Industries/Agriculture';
import Manufacturing from './pages/Industries/Manufacturing';
import Medical from './pages/Industries/Medical';
import Customisation from './pages/Services/Customisation';
import Project from './pages/Services/Project';
import Virtual from './pages/Services/Virtual';
import Civil from './pages/Industries/Civil';
import Mechenical from './pages/Industries/Mechenical';
import Building from './pages/Services/Building';
import Mining from './pages/Industries/Mining';
import Stock from './pages/Stock';
import Plant from './pages/Plant';
import Steel from './pages/Steel';
import Shop from './pages/Shop';
import Prefabricated from './pages/Prefabricated';
import Miningdesign from './pages/Miningdesign';
import Analysis from './pages/Analysis';
import Productdesign from './pages/Productdesign';
import Engineeringdoc from './pages/Engineeringdoc';
import Businessdoc from './pages/Businessdoc';
import Tender from './pages/Tender';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path='/career' element={<Career/>} />
        <Route path='/contact-us' element={<Contact/>}/>
        <Route path='/industries/agriculture' element={<Agriculture/>}/>
        <Route path='/industries/manufacturing' element={<Manufacturing/>}/>
        <Route path='/industries/mining' element={<Mining/>}/>
        <Route path='/industries/medical' element={<Medical/>}/>
        <Route path='/industries/civil' element={<Civil/>}/>
        <Route path='/industries/mechanical' element={<Mechenical/>}/>
        <Route path='/services/engineering-customisation-tools' element={<Customisation/>}/>
        <Route path='/services/engineering-customisation-tools/rolling-stock-design' element={<Stock/>}/>
        <Route path='/services/steel-detailing' element={<Steel/>}/>
        <Route path='/services/shop-drawings' element={<Shop/>}/>
        <Route path='/services/prefabricated-buildings' element={<Prefabricated/>}/>
        <Route path='/services/mining-equipment-design' element={<Miningdesign/>}/>
        <Route path='/services/engineering-analysis-calculations' element={<Analysis/>}/>
        <Route path='/services/product-design' element={<Productdesign/>}/>
        <Route path='/services/process-plant-design' element={<Plant/>}/>
        <Route path='/services/project-management' element={<Project/>}/>
        <Route path='/services/engineering-documentation' element={<Engineeringdoc/>}/>
        <Route path='/services/business-documentation' element={<Businessdoc/>}/>
        <Route path='/services/tender-support-documentation' element={<Tender/>}/>
        <Route path='/services/virtual-reality' element={<Virtual/>}/>
        <Route path='/services/building-information-modeling' element={<Building/>}/>
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
