import React from 'react';
import { Container, Grid, TextField, Button, Typography, Box, Toolbar } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
const Contact = () => {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Box component="main" sx={{ p: 3, bgcolor: '#ede8f5' }}>
      <Toolbar/>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          {/* Left Side - Contact Form */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                padding: 2, // theme.spacing(2) equivalent
              }}
            >
              <Typography variant="h5" sx={{ marginBottom: 2 }}>
                CONTACT US
              </Typography>
              <form noValidate>
                <TextField
                  label="Name"
                  variant="outlined"
                  fullWidth
                  sx={{ marginBottom: 2 }}
                />
                <TextField
                  label="Email"
                  variant="outlined"
                  type="email"
                  fullWidth
                  sx={{ marginBottom: 2 }}
                />
                <TextField
                  label="Message"
                  variant="outlined"
                  multiline
                  rows={4}
                  fullWidth
                  sx={{ marginBottom: 2 }}
                />
                <Button variant="contained" color="primary" sx={{ alignSelf: 'flex-start' }}>
                  Submit
                </Button>
              </form>
            </Box>
          </Grid>

          {/* Right Side - Office Address and Email */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                padding: 2, // theme.spacing(2) equivalent
              }}
            >
              <Typography variant="h5" sx={{ marginBottom: 1 }}>
                Our Office
              </Typography>
              <Typography variant="body1">
              288 Musgrave Road, Coopers Plains, <br/>QLD, Australia 4108
               
              </Typography>
              <Typography variant="h6" sx={{ marginBottom: 1, marginTop: '5px' }}>
                Email Us
              </Typography>
              <Typography variant="body1">admin@aspirecoordination.com</Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Contact;





























// import React from 'react'
// import { Box, Typography, Toolbar } from '@mui/material'
// const Contact = () => {
//   return (
//     <>
//         <Box component="main" sx={{ p: 3, bgcolor: '#ede8f5' }}>
//         <Toolbar/>
//         <Typography variant='h5' sx={{textAlign: 'left', fontWeight: 'bold'}}>Contact Us</Typography>
//         <Typography sx={{textAlign: 'left', marginTop: '15px'}}>
//             Based in Australia, Tech Coordination is an engineering design company that is always looking for new and exciting projects. Please get in touch if you're interested in working with us!
//         </Typography>
//       </Box>
//     </>
//   )
// }

// export default Contact
