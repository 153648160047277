import React from "react";
import { Box, Container, Grid, Typography, IconButton, styled, CardMedia } from "@mui/material";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";  // Importing Link from react-router-dom
import logo from '../assets/2.svg';

const FooterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#1a237e",
  color: "#ffffff",
  padding: theme.spacing(6, 0),
  position: "relative",
  bottom: 0,
  width: "100%"
}));

const FooterHeading = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginBottom: theme.spacing(2),
  fontSize: "1.2rem"
}));

const FooterLink = styled(Typography)(({ theme }) => ({
  color: "#ffffff",
  marginBottom: theme.spacing(1),
  cursor: "pointer",
  fontSize: "1rem",
  "&:hover": {
    color: "#3D52A0",
    transform: "translateX(5px)",
    transition: "all 0.3s ease-in-out"
  }
}));

const SocialIcon = styled(IconButton)(({ theme }) => ({
  color: "#ffffff",
  marginRight: theme.spacing(2),
  "&:hover": {
    color: "#90caf9",
    transform: "translateY(-3px)",
    transition: "all 0.3s ease-in-out"
  }
}));

const CopyrightText = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  marginTop: theme.spacing(4),
  borderTop: "1px solid rgba(255, 255, 255, 0.1)",
  paddingTop: theme.spacing(2)
}));

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <FooterContainer component="footer" role="contentinfo" sx={{bgcolor: '#ADBBDA'}}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={3}>
            <FooterHeading variant="h6" aria-label="Company Information" sx={{color: '#0f0f0f', textTransform: 'uppercase'}}>
              <Link to="/" style={{ textDecoration: 'none' }}>
                <CardMedia
                  sx={{ height: 75, width: 155 }}
                  image={logo}
                  title="Aspire"
                />
              </Link>
            </FooterHeading>
            <FooterLink variant="body2" sx={{color: '#0f0f0f'}}>
              Leading innovation in technology solutions and digital transformation.
            </FooterLink>
          </Grid>

          <Grid item xs={12} sm={6} md={3} sx={{color: '#0f0f0f'}}>
            <FooterHeading variant="h6" aria-label="Help Section">
              Email
              <FooterLink variant="body2" sx={{color: '#0f0f0f'}}>admin@aspirecoordination.com</FooterLink>
            </FooterHeading>

            <FooterHeading variant="h6" aria-label="Help Section">
              Call Us
              <FooterLink variant="body2" sx={{color: '#0f0f0f'}}>+61 403 786 083</FooterLink>
            </FooterHeading>

            <FooterHeading variant="h6" aria-label="Help Section">
              Find Us
              <FooterLink variant="body2" sx={{color: '#0f0f0f'}}>288 Musgrave Road, Coopers Plains, QLD, Australia 4108</FooterLink>
            </FooterHeading>
          </Grid>

          <Grid item xs={12} sm={6} md={3} sx={{color: '#0f0f0f' }}>
            <FooterHeading variant="h6" aria-label="Resources Section" sx={{textTransform: 'uppercase'}}>
              Services
            </FooterHeading>
            <Link to="/services/engineering-customisation-tools/rolling-stock-design" style={{ textDecoration: 'none' }}>
              <FooterLink variant="body2" sx={{color: '#0f0f0f'}}>Engineering Design and Drafting</FooterLink>
            </Link>
            <Link to="/services/engineering-customisation-tools" style={{ textDecoration: 'none' }}>
              <FooterLink variant="body2" sx={{color: '#0f0f0f'}}>Engineering Customisation Tools</FooterLink>
            </Link>
            <Link to="/services/engineering-documentation" style={{ textDecoration: 'none' }}>
              <FooterLink variant="body2" sx={{color: '#0f0f0f'}}>Technical Writing and Documentation</FooterLink>
            </Link>
            <Link to="services/project-management" style={{ textDecoration: 'none' }}>
              <FooterLink variant="body2" sx={{color: '#0f0f0f'}}>Project Management</FooterLink>
            </Link>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <FooterHeading variant="h6" aria-label="Extra Links Section" sx={{color: '#0f0f0f', textTransform: 'uppercase'}}>
              Industries
            </FooterHeading>
            <Link to="/industries/agriculture" style={{ textDecoration: 'none' }}>
              <FooterLink variant="body2" sx={{color: '#0f0f0f'}}>Agriculture</FooterLink>
            </Link>
            <Link to="/industries/manufacturing" style={{ textDecoration: 'none' }}>
              <FooterLink variant="body2" sx={{color: '#0f0f0f'}}>Manufacturing</FooterLink>
            </Link>
            <Link to="/industries/mining" style={{ textDecoration: 'none' }}>
              <FooterLink variant="body2" sx={{color: '#0f0f0f'}}>Mining</FooterLink>
            </Link>
            <Link to="/industries/medical" style={{ textDecoration: 'none' }}>
              <FooterLink variant="body2" sx={{color: '#0f0f0f'}}>Medical</FooterLink>
            </Link>
            <Link to="/industries/civil" style={{ textDecoration: 'none' }}>
              <FooterLink variant="body2" sx={{color: '#0f0f0f'}}>Civil</FooterLink>
            </Link>
            <Link to="/industries/mechanical" style={{ textDecoration: 'none' }}>
              <FooterLink variant="body2" sx={{color: '#0f0f0f'}}>Mechanical</FooterLink>
            </Link>
          </Grid>
        </Grid>

        <Box sx={{ mt: 4, display: "flex", flexDirection: "column", alignItems: "center", color: '#0f0f0f' }}>
          <Box sx={{ mb: 2 ,color: '#0f0f0f' }}>
            <SocialIcon aria-label="Facebook" component="a" href=" https://www.facebook.com/profile.php?viewas=100000686899395&id=61568696157426">
              <FaFacebook size={24} />
            </SocialIcon>
            <SocialIcon aria-label="Twitter" component="a" href="https://x.com/AspireCDN7">
              <FaTwitter size={24} />
            </SocialIcon>
            <SocialIcon aria-label="Instagram" component="a" href="https://www.instagram.com/aspirecoordination7/">
              <FaInstagram size={24} />
            </SocialIcon>
            <SocialIcon aria-label="LinkedIn" component="a" href="https://www.linkedin.com/company/aspire-coordination/">
              <FaLinkedin size={24} />
            </SocialIcon>
          </Box>
          <CopyrightText variant="body2">
            © {currentYear} AspireCorp. All rights reserved.
          </CopyrightText>
        </Box>
      </Container>
    </FooterContainer>
  );
};

export default Footer;
