import React from 'react'
import { Box, Container, Toolbar, Typography,TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import image1 from './assets/brutalist-inspiration-architecture-background.jpg'
import image2 from './assets/white-metallic-structure.jpg'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';  
const standards = [
    { code: 'AS 1100', description: 'Australian Standard for technical drawing including both mechanical and architectural designs. ' },
    { code: 'AS/NZS 1554', description: 'Structural Steel Welding Set' },
    { code: 'AS 1418', description: 'Cranes, Hoists and Winches' },
    { code: 'AS/NZS 4024.1', description: 'Safety of Machinery' },
    { code: 'AS 1657', description: 'Fixed platform, walkways, stairways and ladders- Design, construct and installation' },
    { code: 'AS 4100', description: 'Steel Structure' },
    { code: 'AS/NZS 5131', description: 'Structural Steelwork- Fabrication and Erection' },
];


const Steel = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Box sx={{ bgcolor: '#ede8f5', width: '100%' }}>
      <Container maxWidth="lg">
        <Box component="main" sx={{ p: 3 }}>
          <Toolbar/>
          <Typography variant='h5' sx={{textAlign: 'center', fontWeight: 'bold', mb: 4}}>STEEL DETAILING</Typography>
          <Typography sx={{textAlign: 'left', marginTop: '15px'}}>
          Steel detailing is a technical topic that requires in-depth design and draughting expertise. By providing structural design and shop drawings to clients in a variety of industries, such as mining, residential, building, and heavy construction, our design team at Aspire Coordination Australia Pty Ltd has refined these abilities.
          </Typography>



          {/* Second Section: Text and Image */}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            alignItems: 'stretch',
            gap: 4,
            mb: 4
          }}>
            <Box sx={{ 
              flex: '1 1 50%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
                <Typography sx={{textAlign: 'left', fontWeight: 'bold', marginTop: '15px'}}>Proactive Problem Solving</Typography>
              <Typography sx={{ textAlign: 'left', marginTop: '15px' }}>
              We have an excellent eye for detail and are skilled at seeing hotspots and possible difficulties during the design phase, long before they might become practical issues. Because of our team's ability to anticipate problems, we can put solutions in place early on, preventing expensive rework and guaranteeing that projects are completed on time. For our clients, this proactive strategy results in significant cost savings, improved safety, and more seamless project execution.
              </Typography>
              <Typography sx={{textAlign: 'left', fontWeight: 'bold', marginTop: '15px'}}>Steel Detailing Project Expertise</Typography>
              <Typography sx={{ textAlign: 'left', marginTop: '15px' }}>
              Numerous initiatives have been successfully assisted by our staff, including:
              <ul>
                <li>Architectural Landmark Structures that require precision and innovation to achieve the desired aesthetic and structural integrity.</li>
                <li>Complex Architectural Buildings where intricate detailing and collaboration are essential for successful completion.</li>
                <li>Residential and Commercial Buildings that demand high-quality detailing to meet strict industry standards and client expectations.</li>
              </ul>
              </Typography>
            </Box>
            <Box sx={{
              flex: '1 1 50%',
              minHeight: { xs: 300, md: 400 },
              backgroundImage: `url(${image1})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 2,
            }}/>
          </Box>

          {/* Third Section: Image and Text */}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            alignItems: 'stretch',
            gap: 4,
            mb: 4
          }}>
            <Box sx={{
              flex: '1 1 50%',
              minHeight: { xs: 300, md: 400 },
              backgroundImage: `url(${image2})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 2,
            }}/>
            <Box sx={{ 
              flex: '1 1 50%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              <Typography  sx={{ textAlign: 'center', marginBottom: '20px' }}>
  We strictly follow Australian Standards in our work. Some of the relevant standards are as below
  </Typography>
  <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell><strong>Code</strong></TableCell>
          <TableCell><strong>Description</strong></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {standards.map((standard, index) => (
          <TableRow key={index}>
            <TableCell>{standard.code}</TableCell>
            <TableCell>{standard.description}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
            </Box>
          </Box>

        </Box>
      </Container>
    </Box>
  )
}

export default Steel