import React from 'react'
import { Box, Typography, Toolbar, Container } from '@mui/material'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
const Career = () => {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      

      <Box component="main" sx={{ bgcolor: '#ede8f5' }}>
      <Container
        maxWidth="md"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          bgcolor: '#ede8f5'
        }}
      >
        <Box component="main" sx={{ p: 3, bgcolor: '#ede8f5' }}>
          <Toolbar />
          <Typography variant='h5' sx={{ textAlign: 'center', fontWeight: 'bold' }}>CAREER</Typography>
          <Typography sx={{ textAlign: 'left', marginTop: '15px' }}>
            <ul>
              <li>Aspire Coordination Australia is an innovative design consulting firm that provides a wide array of services, attracting professionals from diverse fields and expertise. Our success is driven by fresh ideas, and we continually seek talented individuals to enhance our projects. We offer a range of career paths, accommodating both short-term and long-term interests, with flexible employment options, including Permanent, Contract, and Casual positions.</li>
              <li sx={{ marginTop: '15px' }}>We at Aspire Coordination Australia provide a number of advantages to help you advance both professionally and personally. We are committed to fostering an environment where learning never stops and there are many chances for professional growth. Your professional network will grow as you have the chance to establish beneficial relationships with customers and suppliers from a variety of industry sectors. In order for our team members to succeed both personally and professionally, we place a strong emphasis on maintaining a healthy work-life balance. We are happy to support diversity and inclusion in our workforce as an equal opportunity employer.</li>
              <li sx={{ marginTop: '15px' }}>We at Aspire Coordination Australia understand that our employees are the key to our success. We are committed to creating a friendly, cooperative atmosphere where everyone may succeed. Experts from a wide range of disciplines make up our team, and we value the unique perspectives and experiences that each person brings to the table. We cultivate a collaborative atmosphere that values originality and creativity. Every day will present new challenges and chances for development, whether you're working on innovative design projects, interacting with clients, or developing your skill set. With an emphasis on career progression, diversity, and employee well-being, Tech Coordination Australia is more than simply a place to work; it's a place where you can have a fulfilling career. Come along and influence the direction of design.</li>
              <li sx={{ marginTop: '15px' }}>Aspire Coordination Australia is the ideal place for you to launch your career if you're a new graduate looking to acquire worthwhile experience. Step one toward an exciting future with us by sending your resume to <Box component="span" sx={{fontWeight: 'bold', color: '#3D52a0'}} >admin@aspirecoordination.com</Box></li>
            </ul>
          </Typography>
        </Box>
      </Container>

      </Box>
    </>
  )
}

export default Career
