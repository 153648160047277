import * as React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { Menu, MenuItem, AppBar, Box, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Toolbar, Typography, Button, CardMedia, Collapse } from '@mui/material';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import logo from '../assets/Add a heading-4.svg';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const drawerWidth = 240;

const navItems = [
  { name: 'Home', path: '/' },
  { name: 'Services', path: '/services' },
  { name: 'Industries', path: '/industries' },
  { name: 'About', path: '/about' },
  { name: 'Career', path: '/career' },
  { name: 'Contact Us', path: '/contact-us' },
  
];

const engineeringDesignSubmenu = [
  { name: 'Rolling Stock Design', path: '/services/engineering-customisation-tools/rolling-stock-design' },
  { name: 'Process Plant Design', path: '/services/process-plant-design' },
  { name: 'Steel Detailing', path: '/services/steel-detailing' },
  { name: 'Shop Drawings', path: '/services/shop-drawings' },
  { name: 'Prefabricated Buildings', path: '/services/prefabricated-buildings' },
  { name: 'Mining Equipment Design', path: '/services/mining-equipment-design' },
  { name: 'Engineering Analysis and Calculation', path: '/services/engineering-analysis-calculations' },
  { name: 'Product Design', path: '/services/product-design' },
];

const technicalWritingSubmenu = [
  { name: 'Engineering Documentation', path: '/services/engineering-documentation' },
  { name: 'Business Documentation', path: '/services/business-documentation' },
  { name: 'Tender support and Documentation', path: '/services/tender-support-documentation' },
];

const industriesSubmenu = [
  { name: 'Agriculture', path: '/industries/agriculture' },
  { name: 'Manufacturing', path: '/industries/manufacturing' },
  { name: 'Mining', path: '/industries/mining' },
  { name: 'Medical', path: '/industries/medical' },
  { name: 'Civil', path: '/industries/civil' },
  { name: 'Mechanical', path: '/industries/mechanical' }
];

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [servicesAnchorEl, setServicesAnchorEl] = React.useState(null);
  const [industriesAnchorEl, setIndustriesAnchorEl] = React.useState(null);
  const [submenuAnchorEl, setSubmenuAnchorEl] = React.useState(null);
  const [submenuAnchorEl2, setSubmenuAnchorEl2] = React.useState(null);
  
  // New states for mobile drawer submenus
  const [servicesOpen, setServicesOpen] = React.useState(false);
  const [industriesOpen, setIndustriesOpen] = React.useState(false);
  const [engineeringDesignOpen, setEngineeringDesignOpen] = React.useState(false);
  const [technicalWritingOpen, setTechnicalWritingOpen] = React.useState(false);

  const closeTimeout = React.useRef();
  const closeSubmenuTimeout = React.useRef();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleMenuOpen = (event, menuType) => {

    if (closeTimeout.current) {
      clearTimeout(closeTimeout.current);
    }
    if (closeSubmenuTimeout.current) {
      clearTimeout(closeSubmenuTimeout.current);
    }

    if (menuType === 'services') setServicesAnchorEl(event.currentTarget);
    if (menuType === 'industries') setIndustriesAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    closeTimeout.current = setTimeout(() => {
      setServicesAnchorEl(null);
      setIndustriesAnchorEl(null);
      setSubmenuAnchorEl(null);
      setSubmenuAnchorEl2(null);
    }, 100);

  };

  const handleSubmenuOpen = (event, submenuType) => {


    if (closeTimeout.current) {
      clearTimeout(closeTimeout.current);
    }
    if (closeSubmenuTimeout.current) {
      clearTimeout(closeSubmenuTimeout.current);
    }

    if (submenuType === 'engineeringDesign') {
      setSubmenuAnchorEl(event.currentTarget);
      setSubmenuAnchorEl2(null); // Close the technical writing submenu
    } else if (submenuType === 'technicalWriting') {
      setSubmenuAnchorEl2(event.currentTarget);
      setSubmenuAnchorEl(null); // Close the engineering design submenu
    }
  };

  const handleSubmenuClose = () => {
    closeSubmenuTimeout.current = setTimeout(() => {
      setSubmenuAnchorEl(null);
      setSubmenuAnchorEl2(null);
    }, 100);

  };

  const handleMenuEnter = () => {
    // Clear timeouts when entering any menu
    if (closeTimeout.current) {
      clearTimeout(closeTimeout.current);
    }
    if (closeSubmenuTimeout.current) {
      clearTimeout(closeSubmenuTimeout.current);
    }
  };

  const handleMenuItemClick = (path) => {
    navigate(path);
    handleMenuClose();
  };

  const handleDrawerItemClick = (path) => {
    if (path) {
      navigate(path);
      setMobileOpen(false);
    }
  };

  const isPathActive = (path) => {
    if (path === '/') {
      return location.pathname === '/';
    }
    return location.pathname.startsWith(path);
  };


  const isServicesActive = () => {
    return location.pathname.includes('/services') ||
           engineeringDesignSubmenu.some(item => location.pathname === item.path) ||
           technicalWritingSubmenu.some(item => location.pathname === item.path);
  };

  // Helper function to check if industries menu should be highlighted
  const isIndustriesActive = () => {
    return location.pathname.includes('/industries') ||
           industriesSubmenu.some(item => location.pathname === item.path);
  };

  // Style for active menu items
  const activeMenuStyle = {
    color: '#9fafca',
    borderBottom: '2px solid #9fafca',
  };

  const renderDrawer = (
    <Box sx={{ 
      height: '100vh', // Full viewport height
      display: 'flex',
      flexDirection: 'column',
      bgcolor: '#3D52a0',
      color: 'white',
      overflowY: 'auto' 
       }}>
      <Typography variant="h6" sx={{ my: 2 }} 
        onClick={() => {
          handleMenuItemClick('/');
          handleDrawerToggle(); // drawer close function
        }}
        >
        <CardMedia sx={{ height: 90 }} image={logo} title="Aspire" />
      </Typography>
    
      <Divider sx={{ bgcolor: 'rgba(255, 255, 255, 0.2)' }} />
      <List
        sx={{
          flex: 1,
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'rgba(255, 255, 255, 0.1)',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'rgba(255, 255, 255, 0.2)',
            borderRadius: '3px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: 'rgba(255, 255, 255, 0.3)',
          }
        }}
      >
        <ListItem>
          <ListItemButton onClick={() => handleDrawerItemClick('/')}
          >
            <ListItemText primary="Home" />
          </ListItemButton>
        </ListItem>

        {/* Services Menu */}
        <ListItem>
          <ListItemButton onClick={() => setServicesOpen(!servicesOpen)}
          >
            <ListItemText primary="Services" />
            {servicesOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={servicesOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {/* Engineering Design Submenu */}
            <ListItem sx={{ pl: 4 }}>
              <ListItemButton onClick={() => setEngineeringDesignOpen(!engineeringDesignOpen)}>
                <ListItemText primary="Engineering Design and Drafting" />
                {engineeringDesignOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </ListItem>
            <Collapse in={engineeringDesignOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {engineeringDesignSubmenu.map((item) => (
                  <ListItem key={item.name} sx={{ pl: 6 }}>
                    <ListItemButton onClick={() => handleDrawerItemClick(item.path)}>
                      <ListItemText primary={item.name} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Collapse>

            <ListItem sx={{ pl: 4 }}>
              <ListItemButton onClick={() => handleDrawerItemClick('/services/engineering-customisation-tools')}>
                <ListItemText primary="Engineering Customisation Tools" />
              </ListItemButton>
            </ListItem>

            <ListItem sx={{ pl: 4 }}>
              <ListItemButton onClick={() => handleDrawerItemClick('/services/project-management')}>
                <ListItemText primary="Project Management" />
              </ListItemButton>
            </ListItem>

            {/* Technical Writing Submenu */}
            <ListItem sx={{ pl: 4 }}>
              <ListItemButton onClick={() => setTechnicalWritingOpen(!technicalWritingOpen)}>
                <ListItemText primary="Technical Writing and Documentation" />
                {technicalWritingOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </ListItem>
            <Collapse in={technicalWritingOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {technicalWritingSubmenu.map((item) => (
                  <ListItem key={item.name} sx={{ pl: 6 }}>
                    <ListItemButton onClick={() => handleDrawerItemClick(item.path)}>
                      <ListItemText primary={item.name} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </List>
        </Collapse>

        {/* Industries Menu */}
        <ListItem>
          <ListItemButton onClick={() => setIndustriesOpen(!industriesOpen)}>
            <ListItemText primary="Industries" />
            {industriesOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={industriesOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {industriesSubmenu.map((item) => (
              <ListItem key={item.name} sx={{ pl: 4 }}>
                <ListItemButton onClick={() => handleDrawerItemClick(item.path)}>
                  <ListItemText primary={item.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Collapse>

        {/* Other navigation items */}
        {navItems
          .filter(item => !['Home', 'Services', 'Industries'].includes(item.name))
          .map((item) => (
            <ListItem key={item.name}>
              <ListItemButton onClick={() => handleDrawerItemClick(item.path)}>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
    </Box>
  );

  return (
    <>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar component="nav" sx={{ bgcolor: '#3D52A0' }}>
            <Toolbar >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { xs: 'block', md: 'none' } }} // Display on screens smaller than 812px
              >
                <MenuIcon />
              </IconButton>
              <Box
                        sx={{
                          flexGrow: 1,
                          display: { xs: 'none', md: 'flex' },
                          alignItems: 'center'
                        }}
                      >
                        <Box
                          onClick={() => handleMenuItemClick('/')}
                          sx={{
                            width: 'fit-content',
                            cursor: 'pointer'
                          }}
                        >
                          <CardMedia 
                            className="logo"
                            sx={{ 
                              height: 65, 
                              width: 105,
                              transition: 'all 0.3s ease-in-out',
                            }} 
                            image={logo} 
                            title="Aspire" 
                          />
                        </Box>
              </Box>
              <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                {navItems.map((item) => (
                  <Button
                    key={item.name}
                    sx={{
                      color: '#fff',
                      mx: 1,
                      pb: 0.5,
                      position: 'relative',
                      '&::after': {
                        content: '""',
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        height: '2px',
                        backgroundColor: '#9fafca',
                        transform: 'scaleX(0)',
                        transition: 'transform 0.3s ease-in-out',
                      },
                      ...(
                        (item.name === 'Services' && isServicesActive()) ||
                        (item.name === 'Industries' && isIndustriesActive()) ||
                        (item.path !== '/' && isPathActive(item.path)) ||
                        (item.path === '/' && location.pathname === '/')
                          ? {
                              color: '#9fafca',
                              '&::after': {
                                transform: 'scaleX(1)',
                              }
                            }
                          : {}
                      ),
                      '&:hover': {
                        color: '#9fafca',
                        '&::after': {
                          transform: 'scaleX(1)',
                        }
                      },
                    }}
                    onMouseEnter={(event) =>
                      item.name === 'Services'
                        ? handleMenuOpen(event, 'services')
                        : item.name === 'Industries'
                        ? handleMenuOpen(event, 'industries')
                        : null
                    }
                    onClick={() => handleMenuItemClick(item.path)}
                  >
                    {item.name}
                  </Button>
                ))}
              </Box>
            </Toolbar>

          </AppBar>

          {/* Services Dropdown */}
          <Menu
            anchorEl={servicesAnchorEl}
            open={Boolean(servicesAnchorEl)}
            onClose={handleMenuClose}
            sx={{cursor: 'pointer', marginTop: '12px'}}
            MenuListProps={{
              onMouseLeave: handleMenuClose,
              onMouseEnter: handleMenuEnter,
              sx: { minWidth: '250px' }
            }}
          >
            <MenuItem onMouseEnter={(e) => handleSubmenuOpen(e, 'engineeringDesign')}
            sx={{
              color: location.pathname.includes('/services/engineering') ? '#3D52A0' : 'inherit',
              backgroundColor: location.pathname.includes('/services/engineering') ? 'rgba(61, 82, 160, 0.1)' : 'inherit',
              '&:hover': {
                backgroundColor: 'rgba(61, 82, 160, 0.1)',
              }
            }}>
              Engineering Design and Drafting <ArrowRightIcon />
            </MenuItem>
            <Menu
              anchorEl={submenuAnchorEl}
              open={Boolean(submenuAnchorEl)}
              onClose={handleSubmenuClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              MenuListProps={{
                onMouseLeave: handleSubmenuClose,
                onMouseEnter: handleMenuEnter,
                sx: { minWidth: '200px' }
              }}
            >
              {engineeringDesignSubmenu.map((item) => (
                <MenuItem key={item.name} onClick={() => handleMenuItemClick(item.path)}>
                  {item.name}
                </MenuItem>
              ))}
            </Menu>

            <MenuItem onClick={() => handleMenuItemClick('/services/engineering-customisation-tools')}>
              Engineering Customisation Tools
            </MenuItem>
            <MenuItem onClick={() => handleMenuItemClick('/services/project-management')}>
              Project Management
            </MenuItem>
            <MenuItem onMouseEnter={(e) => handleSubmenuOpen(e, 'technicalWriting')}>
              Technical Writing and Documentation <ArrowRightIcon />
            </MenuItem>
            <Menu
              anchorEl={submenuAnchorEl2}
              open={Boolean(submenuAnchorEl2)}
              onClose={handleSubmenuClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              MenuListProps={{
                onMouseLeave: handleSubmenuClose,
                sx: { minWidth: '200px' }
              }}
            >
              {technicalWritingSubmenu.map((item) => (
                <MenuItem key={item.name} onClick={() => handleMenuItemClick(item.path)}>
                  {item.name}
                </MenuItem>
              ))}
            </Menu>
          </Menu>

          {/* Industries Dropdown */}
          <Menu
            anchorEl={industriesAnchorEl}
            open={Boolean(industriesAnchorEl)}
            onClose={handleMenuClose}
            sx={{cursor: 'pointer', marginTop: '12px'}}
            MenuListProps={{
              onMouseLeave: handleMenuClose,
              onMouseEnter: handleMenuEnter,
              sx: { minWidth: '250px' }
            }}
          >
            {industriesSubmenu.map((item) => (
              <MenuItem key={item.name} onClick={() => handleMenuItemClick(item.path)}>
                {item.name}
              </MenuItem>
            ))}
          </Menu>

          {/* Drawer */}
          <nav aria-label="main navigation">
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              sx={{
                display: { xs: 'block', md: 'none' },
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: drawerWidth,
                },
              }}
            >
              {renderDrawer}
            </Drawer>
          </nav>
        </Box>
    </>
    
  );
}

export default Header;