import React from 'react'
import { Box,  Toolbar, Typography, Container } from '@mui/material';
import image1 from '../assets/factory-workers-with-face-masks-protected-against-corona-virus-doing-quality-control-production-factory.jpg'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
const Medical = () => {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
        

        <Box sx={{ bgcolor: '#ede8f5', width: '100%' }}>
      <Container maxWidth="lg">
        <Box component="main" sx={{ p: 3 }}>
          <Toolbar/>
          <Typography variant='h5' sx={{textAlign: 'center', fontWeight: 'bold'}}>MEDICAL</Typography>
            <Typography sx={{ textAlign: 'left', marginTop: '15px' }}>
            At Aspire Coordination Australia Pty Ltd, our engineering team brings a wealth of experience in
            <Box component="span" sx={{ fontWeight: 'bold', marginLeft: 1, marginRight: 1 }}>
                Engineering Design, Drafting, and Documentation
            </Box>, specializing in the 
            <Box component="span" sx={{ fontWeight: 'bold', marginLeft: 1, marginRight: 1 }}>
            Medical Equipment (ME)
            </Box>industry.  We have been delivering design and drafting services to ME companies, and have since broadened our services to include technical documentation and user manuals.
            </Typography>

            <Typography sx={{ textAlign: 'left', marginTop: '15px' }}>
            Our work on
            <Box component="span" sx={{ fontWeight: 'bold', marginLeft: 1, marginRight: 1 }}>
            operation and maintenance manuals 
            </Box>
             for a dental equipment firm is a noteworthy success story that received a lot of praise and recognition from the industry.
            </Typography>

            <Typography sx={{ textAlign: 'left', marginTop: '15px' }}>
            Over the years, we have developed a deep understanding of the
            <Box component="span" sx={{ fontWeight: 'bold', marginLeft: 1, marginRight: 1 }}>
            Medical Equipment 
            </Box>
            sector, particularly in terms of 
            <Box component="span" sx={{ fontWeight: 'bold', marginLeft: 1}}>
            Engineering Processes, Procedures
            </Box>, and 
            <Box component="span" sx={{ fontWeight: 'bold', marginLeft: 1, marginRight: 1 }}>
            Standards.
            </Box>
            Our engineers are well-versed in relevant Australian Standards for the medical industry, such as:
            <ul>
                <li><Box component="span" sx={{ fontWeight: 'bold', marginLeft: 1, marginRight: 1 }}>
                        AS/NZS 3551
                    </Box> : Management programs for medical equipment.
                </li>
                <li><Box component="span" sx={{ fontWeight: 'bold', marginLeft: 1, marginRight: 1 }}>
                        AS/NZS 2500
                    </Box> : Guide to the safe use of electricity in patient care settings.
                </li>
            </ul>
            </Typography>

          {/* First Section: Image and Text */}
          <Typography sx={{ textAlign: 'left', fontWeight:'bold' }}>
                Engineering Design and Drafting Services:
                </Typography>
                <Typography sx={{ textAlign: 'left', marginTop: '15px' }}>
                Our group has extensive expertise designing a range of medical equipment systems and components, such as:
                <ul>
                    <li>Enclosures</li>
                    <li>Internal Panels</li>
                    <li>Plastic Mouldings</li>
                    <li>Sheet Metal Components</li>
                    <li>Air Cooling Fins</li>
                    <li>Mechanical Systems</li>
                    <li>Drains</li>
                    <li>Air and Water Supply Systems</li>
                </ul>
            </Typography>

            <Typography sx={{ textAlign: 'left', fontWeight:'bold' }}>
                Comprehensive Documentation Services
                </Typography>
                <Typography sx={{ textAlign: 'left', marginTop: '15px' }}>
                In addition to design and drafting, we offer a full suite of documentation services, including:
                <ul>
                    <li>Standard Operating Procedures (SOP)</li>
                    <li>User Manuals</li>
                    <li>Standard Work Instructions (SWI)</li>
                    <li>Service/Maintenance Manuals</li>
                    <li>Installation Manuals</li>
                </ul>
                </Typography>


                <Typography sx={{ textAlign: 'left', marginTop: '15px' }}>
            At Aspire Coordination, we focus on delivering high-caliber engineering solutions and documentation tailored to meet the exacting requirements of the medical sector.
            </Typography>

        </Box>
      </Container>
    </Box>
    </>
  )
}

export default Medical
