import React from 'react'
import { Box, Container, Toolbar, Typography,TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import image1 from './assets/closeup-shot-train-desert-cloudy-sky.jpg'
import image2 from './assets/men-warehouse-working-laptop.jpg'
import image3 from './assets/pop-punk-aesthetic-portrait-woman-posing-locomotive.jpg'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';  

const standards = [
    { code: 'AS 7519', description: 'Railway Rolling Stock – Bogie Structural Requirement – Freight Rolling Stock' },
    { code: 'AS 7501', description: 'Rolling stock compliance certificate' },
    { code: 'AS 7529', description: 'Railway Rolling Stock – Fire Safety Passenger Rolling stock' },
];


const Stock = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Box sx={{ bgcolor: '#ede8f5', width: '100%' }}>
      <Container maxWidth="lg">
        <Box component="main" sx={{ p: 3 }}>
          <Toolbar/>
          <Typography variant='h5' sx={{textAlign: 'center', fontWeight: 'bold', mb: 4}}>ROLLING STOCK DESIGN</Typography>
          <Typography sx={{textAlign: 'left', marginTop: '15px'}}>
          Rolling stock design and draughting are areas in which our Rail team at Aspire Coordination Australia Pty Ltd excels. Our expertise in freight and passenger train design ensures that major rail enterprises around Australia receive top-notch service. Every project we work on is driven by our dedication to safety and innovation, which helps our clients stay ahead of the competition in the rail sector.
          </Typography>


          

          {/* Second Section: Text and Image */}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            alignItems: 'stretch',
            gap: 4,
            mb: 4,
            marginTop: '15px'
          }}>
            <Box sx={{ 
              flex: '1 1 50%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              <Typography sx={{textAlign: 'left', fontWeight: 'bold'}}>Locomotive Modification Design</Typography>
              <Typography sx={{ textAlign: 'left', marginTop: '15px' }}>
              Our team is very skilled at creating updates to vital rail subsystems that satisfy the most recent operational and industry standards. We provide specialised services in wheel assembly modification to maximise performance and prolong the life of rolling stock components, ATP brake installation to improve train control and safety systems, and operator cabin redesign to increase operator ergonomics and safety.
              </Typography>
              <Typography sx={{textAlign: 'left', fontWeight: 'bold', marginTop: '15px'}}>Advanced Software Expertise in Rolling Stock Design</Typography>
              <Typography sx={{ textAlign: 'left', marginTop: '15px' }}>
              To guarantee accuracy and effectiveness in our designs, we make use of cutting-edge software technologies that are frequently utilised in the rolling stock business. With our team's expertise in Siemens UG NX, Teamcenter-PLM, SolidWorks, AutoCAD, Maximo, and Clyman, we can provide innovative design solutions that are tailored to our clients' unique requirements. These tools enable us to handle complex data and produce precise, in-depth models, guaranteeing that every project is finished on schedule and within budget.
              </Typography>
            </Box>
            <Box sx={{
              flex: '1 1 50%',
              minHeight: { xs: 300, md: 400 },
              backgroundImage: `url(${image2})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 2,
            }}/>
          </Box>

          {/* Third Section: Image and Text */}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            alignItems: 'stretch',
            gap: 4,
            mb: 4
          }}>
            <Box sx={{
              flex: '1 1 50%',
              minHeight: { xs: 300, md: 400 },
              backgroundImage: `url(${image3})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 2,
            }}/>
            <Box sx={{ 
              flex: '1 1 50%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              <Typography  sx={{ textAlign: 'center', marginBottom: '20px' }}>
  We strictly follow Australian Standards in our work. Some of the relevant standards are as below
  </Typography>
  <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell><strong>Code</strong></TableCell>
          <TableCell><strong>Description</strong></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {standards.map((standard, index) => (
          <TableRow key={index}>
            <TableCell>{standard.code}</TableCell>
            <TableCell>{standard.description}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
            </Box>
          </Box>

        </Box>
      </Container>
    </Box>
  )
}

export default Stock