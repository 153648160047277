import React from 'react'
import { Box, Container, Toolbar, Typography } from '@mui/material';
import image1 from '../assets/two-asian-colleagues-standing-by-window-with-charts-diagrams.jpg'
import image2 from '../assets/business-scene-top-view.jpg'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
const Project = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Box sx={{ bgcolor: '#ede8f5', width: '100%' }}>
      <Container maxWidth="lg">
        <Box component="main" sx={{ p: 3 }}>
          <Toolbar/>
          <Typography variant='h5' sx={{textAlign: 'center', fontWeight: 'bold', mb: 4}}>
            PROJECT MANAGEMENT
          </Typography>
          
          <Typography sx={{textAlign: 'left', mb: 2}}>
            A group of extremely skilled and knowledgeable project management experts with certifications in various approaches make up Tech Coordination. Experts with over ten years of Australian experience in project management have successfully completed several technology projects with a range of budgets in both the public and private sectors of Australia.
          </Typography>
          
          <Typography sx={{textAlign: 'left', mb: 4}}>
            We can take ownership of your projects and manage them end-to-end or assist your PMs with those tasks that could free up their time to focus on more important aspects of the projects. OR our expert team can just provide assistance with the project documentation and reports that you may need on regular basis.
          </Typography>

          {/* First Section: Image and Text */}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            alignItems: 'stretch',
            gap: 4,
            mb: 4
          }}>
            <Box sx={{
              flex: '1 1 50%',
              minHeight: { xs: 300, md: 400 },
              backgroundImage: `url(${image1})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 2,
            }}/>
            <Box sx={{ 
              flex: '1 1 50%', 
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              <Typography sx={{ textAlign: 'left', mb: 1 }}>
                Our team possesses diverse expertise across various fields, such as IT and non-IT Project Management, Project Coordination, PMO, Business Analysis, Change Management, and Contract Administration.
              </Typography>
              <Typography sx={{ textAlign: 'left', mb: 1 }}>
                We are proficient in a range of project management methodologies, including PMBOK, PRINCE2, PROSCI, and AGILE frameworks.
              </Typography>
              <Typography sx={{ textAlign: 'left', mb: 1 }}>
                With extensive experience working with both local and international teams, we excel in managing virtual and global IT and non-IT vendors.
              </Typography>
              <Typography sx={{ textAlign: 'left', mb: 1 }}>
                We specialize in overseeing project budgets and financials, offering the flexibility to create customized budget templates or adapt to your preferred formats. We are also well-versed in Earned Value Management (EVM).
              </Typography>
              <Typography sx={{ textAlign: 'left', mb: 1 }}>
                Our experience extends to the creation of comprehensive Programs, Project Reports, RAID logs, and governance documents for PMOs.
              </Typography>
              <Typography sx={{ textAlign: 'left' }}>
                The team is adept at using a wide range of tools necessary for project management, including ERP systems, Project Management software, Work Management Tools, and CMS. We have hands-on experience with software like MS Project, CA Clarity PPM, Jira, SAP, Oracle EBS, Advanced Excel, VBA, HTML, MS Office Suite, Objective, and SharePoint.
              </Typography>
            </Box>
          </Box>

          {/* Second Section: Text and Image */}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            alignItems: 'stretch',
            gap: 4
          }}>
            <Box sx={{ 
              flex: '1 1 50%', 
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              <Typography sx={{ textAlign: 'left', fontWeight: 'bold', mb: 2 }}>
                Project Management Support & Documentation Services
              </Typography>
              <Typography component="div" sx={{ textAlign: 'left' }}>
                <Box sx={{ mb: 2 }}>Development of project charters, statements of work, contracts, requirements documentation, and stakeholder registers.</Box>
                <Box sx={{ mb: 2 }}>Creation and ongoing maintenance of Project Plans/Programs and GANTT charts.</Box>
                <Box sx={{ mb: 2 }}>Preparation of Daily, Weekly, and Monthly Project Status Updates.</Box>
                <Box sx={{ mb: 2 }}>Production of Project Control and Governance Documents.</Box>
                <Box sx={{ mb: 2 }}>Management of Project Budgets, Forecasts, and Financials.</Box>
                <Box sx={{ mb: 2 }}>Conducting Cash Flow Analysis and implementing Earned Value Management (EVM).</Box>
                <Box sx={{ mb: 2 }}>Resource Management and Planning.</Box>
                <Box sx={{ mb: 2 }}>Design and delivery of Presentation Packs for Steering Committees, gate reviews, and other key meetings.</Box>
                <Box sx={{ mb: 2 }}>Maintenance of RAID logs (Risks, Assumptions, Issues, Dependencies).</Box>
                <Box sx={{ mb: 2 }}>Management of Purchase Orders, Invoices, and Expenditure Reports.</Box>
              </Typography>
            </Box>
            <Box sx={{
              flex: '1 1 50%',
              minHeight: { xs: 300, md: 400 },
              backgroundImage: `url(${image2})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 2,
            }}/>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default Project