import React from 'react'
import { Box, Container, Toolbar, Typography } from '@mui/material';
import image1 from './assets/cad-engineer-working-with-manufacturing-software-develop-industrial-machinery-gears-model-engineering-technical-machine-product-computer-construction-industry-development.jpg'
import image2 from './assets/side-view-mature-man-analyzing-documents-night.jpg'
import image3 from './assets/shoulder-shot-business-man-drawing-diagrams-wall-poster.jpg'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react'; 
const Analysis = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Box sx={{ bgcolor: '#ede8f5', width: '100%' }}>
      <Container maxWidth="lg">
        <Box component="main" sx={{ p: 3 }}>
          <Toolbar/>
          <Typography variant='h5' sx={{textAlign: 'center', fontWeight: 'bold', mb: 4}}>ENGINEERING ANALYSIS & CALCULATIONS</Typography>
          <Typography sx={{textAlign: 'left', marginTop: '15px'}}>
          Our engineering team at Aspire Coordination Australia Pty Ltd is made up of design experts who are highly skilled in engineering analysis and calculations. System, product, and component design are all included in this. To ensure the highest performance standards, our engineers are skilled in evaluating the strength, temperature, dependability, and longevity of parts and assemblies. 
          </Typography>

          {/* First Section: Image and Text */}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            alignItems: 'stretch',
            gap: 4,
            mb: 4,
            marginTop: '15px'
          }}>
            <Box sx={{
              flex: '1 1 50%',
              minHeight: { xs: 300, md: 400 },
              backgroundImage: `url(${image1})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 2,
            }}/>
            <Box sx={{ 
              flex: '1 1 50%', 
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
                <Typography sx={{textAlign: 'left', fontWeight: 'bold'}}>State-of-the-Art Engineering Analysis & Calculations </Typography>
              <Typography sx={{ textAlign: 'left' }}>
              Our state-of-the-art engineering analysis of parts and assemblies has several advantages, such as
              <ul>
                <li>Optimized Design: Achieving the ideal balance between strength and weight. </li>
                <li>Reduced Component Testing Time: Faster validation and iteration. </li>
                <li>Part Light-Weighting: Minimizing material use without compromising strength.  </li>
                <li>Improved Strength and Reliability: Enhancing overall product durability. </li>
                <li>Increased Part Quality: Ensuring superior performance and longevity.  </li>
                <li>Cost Reduction: Significant savings through optimization and material efficiency. </li>
              </ul>
              </Typography>
            </Box>
          </Box>

          {/* Second Section: Text and Image */}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            alignItems: 'stretch',
            gap: 4,
            mb: 4
          }}>
            <Box sx={{ 
              flex: '1 1 50%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
                <Typography sx={{textAlign: 'left', fontWeight: 'bold'}}>Finite Element Engineering Analysis and Calculation Expertise  </Typography>
              <Typography sx={{ textAlign: 'left' }}>
              We evaluate and optimise designs by enhancing the products' strength, mass, stiffness, and vibration performance to satisfy the most exacting performance standards using sophisticated FEA tools and experimental computations. Both pre-processing and post-processing of finite element analysis and simulation are areas in which we specialise. We are skilled in a number of FEA programs, such as
                <ul>
                  <li>Ansys </li>
                  <li>SolidWorks Simulation </li>
                  <li>NX Advanced Simulation </li>
                </ul>
              </Typography>
              <Typography sx={{ textAlign: 'left' }}>
              Our method involves creating FEA-friendly 3D model geometries from the ground up or streamlining pre-existing models by eliminating unnecessary features, which is essential for cutting down on computation time and complexity. In order to simplify geometry, we had previously created macros utilising CAD Application Programming Interfaces (APIs). 
              </Typography>
            </Box>
            <Box sx={{
              flex: '1 1 50%',
              minHeight: { xs: 300, md: 400 },
              backgroundImage: `url(${image2})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 2,
            }}/>
          </Box>

          {/* Third Section: Image and Text */}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            alignItems: 'stretch',
            gap: 4,
            mb: 4
          }}>
            <Box sx={{
              flex: '1 1 50%',
              minHeight: { xs: 300, md: 400 },
              backgroundImage: `url(${image3})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 2,
            }}/>
            <Box sx={{ 
              flex: '1 1 50%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
                <Typography sx={{textAlign: 'left', fontWeight: 'bold'}}>Collaborative Process and Client Engagement  </Typography>
              <Typography sx={{ textAlign: 'left' }}>
              Working closely with clients, our FE analysts collect vital data regarding boundary conditions, load definitions, and material qualities. We get client approval before processing solutions to make sure all specifications are fulfilled.  We confer with the customer at each strategic stage, analysing and verifying findings to generate precise, trustworthy reports. This cooperative strategy ensures that we provide the finest value, specifically catered to the needs of our clients. 
              </Typography>

              <Typography sx={{textAlign: 'left', fontWeight: 'bold', marginTop: '15px'}}>Technological Innovation in Design  </Typography>
              <Typography sx={{ textAlign: 'left' }}>
              Using cutting-edge technology like NX-Kinematics, which uses 3D models to represent moving objects, our team has created solutions. A wide range of engineering problems, from structural calculations to airflow analysis in ducting design, have been successfully analysed and resolved by us using a variety of sophisticated CAD programs. 
              </Typography>
            </Box>
          </Box>

        </Box>
      </Container>
    </Box>
  )
}

export default Analysis