import React from 'react'
import { Box, Container, Toolbar, Typography,TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import image1 from './assets/heavy-machinery-used-construction-industry-engineering.jpg'
import image2 from './assets/limestone-quarry-with-sparse-vegetation-old-abandoned-rusty-excavator.jpg'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react'; 

const standards = [
    { label: 'Mining', code: 'AS 1755', description: 'Conveyor – Safety requirements' },
    { label: 'Mining', code: 'AS 4368', description: 'Mine Plans – Preparation and Symbols' },
    { label: 'General', code: 'AS /NZS 4801', description: 'Saftey Management System' },
];


const Miningdesign = () => {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Box sx={{ bgcolor: '#ede8f5', width: '100%' }}>
      <Container maxWidth="lg">
        <Box component="main" sx={{ p: 3 }}>
          <Toolbar/>
          <Typography variant='h5' sx={{textAlign: 'center', fontWeight: 'bold', mb: 4}}>MINING EQUIPMENT DESIGN</Typography>
          <Typography sx={{textAlign: 'left', marginTop: '15px'}}>
          Our services, which currently include a broad variety of 2D and 3D draughting demands in the mining and petroleum industries, have evolved along with our ever growing clientele. Our proficiency in designing mining equipment encompasses a wide range of tasks, including intricate, extensive undertakings where accuracy and dependability are crucial.
          </Typography>
          {/* First Section: Image and Text */}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            alignItems: 'stretch',
            gap: 4,
            mb: 4,
            marginTop: '15px'
          }}>
            <Box sx={{
              flex: '1 1 50%',
              minHeight: { xs: 300, md: 400 },
              backgroundImage: `url(${image1})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 2,
            }}/>
            <Box sx={{ 
              flex: '1 1 50%', 
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
            <Typography sx={{textAlign: 'left'}}>
                <ul>
                    <li>
                    <Typography sx={{ textAlign: 'left' }}>
              In order to ensure that the highest safety and durability standards are met, we specialise in developing concrete foundations, slabs, equipment bases, and structures including buildings, tanks, and skid-mounted equipment.
              </Typography>
                    </li>
                    <li>
                    <Typography sx={{ textAlign: 'left' }}>
              We offer creative solutions that are suited to the particular difficulties of each project, including the design of complex mechanical parts, assemblies, and specialised machinery like drill rigs, salt harvesters, head frames, and rail-converted vehicles.
              </Typography>
                    </li>

                    <li>
                    <Typography sx={{ textAlign: 'left' }}>
              In order to improve operational efficiency, we provide thorough draughting for infrastructure projects such as pump stations, water purification systems, potable water reticulation, sewerage projects, bore field works and swimming pool plant rooms.
              </Typography>
                    </li>

                    <li>
                    <Typography sx={{ textAlign: 'left' }}>
              In accordance with marine laws and industry standards, we have experience draughting for offshore fixed structures, jackups, semi-submersible vessels, barge equipment fit-outs, and port and harbour works.
              </Typography>
                    </li>

                    <li>
                    <Typography sx={{ textAlign: 'left' }}>
              We also provide specialized drafting services, including aerodrome drawings, catering to the specific needs of niche industries and highly specialized projects.
              </Typography>
                    </li>
                </ul>
            </Typography>

            </Box>
          </Box>

          {/* Third Section: Image and Text */}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            alignItems: 'stretch',
            gap: 4,
            mb: 4
          }}>
            <Box sx={{
              flex: '1 1 50%',
              minHeight: { xs: 300, md: 400 },
              backgroundImage: `url(${image2})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 2,
            }}/>
            <Box sx={{ 
              flex: '1 1 50%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              <Typography  sx={{ textAlign: 'center', marginBottom: '20px' }}>
  We strictly follow Australian Standards in our work. Some of the relevant standards are as below
  </Typography>
  <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
        <TableCell><strong>Lable</strong></TableCell>
          <TableCell><strong>Code</strong></TableCell>
          <TableCell><strong>Description</strong></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {standards.map((standard, index) => (
          <TableRow key={index}>
            <TableCell>{standard.code}</TableCell>
            <TableCell>{standard.description}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
            </Box>
          </Box>

          <Typography sx={{textAlign: 'left', marginTop: '15px'}}>
          Our dedication to providing top-notch mining equipment design and associated draughting services has made us a reliable partner for challenging projects in a variety of sectors. Our clients appreciate our ability to provide precise, thorough, and high-quality drawings that help their projects be carried out successfully from beginning to end. We consistently make investments in state-of-the-art equipment and knowledgeable staff to guarantee that we fulfil and surpass the demands of our wide range of clients, enhancing our standing as market leaders.
          </Typography>

        </Box>
      </Container>
    </Box>
  )
}

export default Miningdesign