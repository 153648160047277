import React from 'react'
import { Box, Container, Toolbar, Typography } from '@mui/material';
import image1 from './assets/entrepreneur-analyzing-business-stats-documents.jpg'
import image2 from './assets/businessman-analysis-working-discussing-charts-graphs-showing-results.jpg'

const Businessdoc = () => {
  return (
    <Box sx={{ bgcolor: '#ede8f5', width: '100%' }}>
      <Container maxWidth="lg">
        <Box component="main" sx={{ p: 3 }}>
          <Toolbar/>
          <Typography variant='h5' sx={{textAlign: 'center', fontWeight: 'bold', mb: 4}}>BUSINESS DOCUMENTATION</Typography>
          <Typography sx={{textAlign: 'left', marginTop: '15px'}}>
          You can get help with a variety of business and marketing documentation from the Aspire Coordination team. Our group has expertise gathering and compiling information by speaking with various parties. Brainstorming, mind mapping, interviews, SWOT, MOST, Process Design, QFD, and FMEA are just a few of the business analysis methods we employ. We are able to create a wide variety of templates and commercial and organisational documentation. 
          </Typography>

          <Typography sx={{textAlign: 'left', marginTop: '15px'}}>
          Our team can provide value to your business at a fair price because we have experience with a variety of business types, sizes, and variations. To improve the turnaround time for document delivery, we could collaborate with you as an extension of your company.
          </Typography>

          <Typography sx={{textAlign: 'left', fontWeight: 'bold', marginTop: '15px'}}>Our team will</Typography>
          <Typography sx={{textAlign: 'left'}}>
          <ul>
            <li>Gain an understanding of your business quickly</li>
            <li>Become an extension of your team</li>
            <li>Liaise with internal and external stakeholders</li>
            <li>Help for a few weeks or a few months to come up with the documentation needed</li>
          </ul>
          </Typography>


          {/* First Section: Image and Text */}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            alignItems: 'stretch',
            gap: 4,
            mb: 4
          }}>
            <Box sx={{
              flex: '1 1 50%',
              minHeight: { xs: 300, md: 400 },
              backgroundImage: `url(${image1})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 2,
            }}/>
            <Box sx={{ 
              flex: '1 1 50%', 
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
                <Typography sx={{textAlign: 'left', fontWeight: 'bold', marginTop: '15px'}}>Business Process Maps</Typography>
              <Typography sx={{ textAlign: 'left', marginTop: '15px' }}>
              We help create organisational process maps that illustrate the steps necessary in establishing your company's entity, accountable individuals, and business process standards. 
              </Typography>
              <Typography sx={{ textAlign: 'left', marginTop: '5px' }}>
              Business process mapping's primary goal is to help organisations become more efficient. A systematic approach to quality management is always preferable, according to ISO (International Organisation for Standardisation). Understanding the relationships and interactions between each process within a department or organisation is made easier with the use of process mapping.
              </Typography>
              <Typography sx={{ textAlign: 'left' , marginTop: '5px'}}>
              We could work on the operational process maps for particular tasks and functions or the individual processes that are involved in various business functions. For example, we have created process maps for an engineering organization's change management procedure. Additionally, we have created a number of process documents that outline the various duties that must be completed every day in a change management cycle.    
              </Typography>
            </Box>
          </Box>

          {/* Second Section: Text and Image */}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            alignItems: 'stretch',
            gap: 4,
            mb: 4
          }}>
            <Box sx={{ 
              flex: '1 1 50%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
                <Typography sx={{textAlign: 'left', fontWeight: 'bold', marginTop: '15px'}}>Policies and Procedures</Typography>
              <Typography sx={{ textAlign: 'left', marginTop: '15px' }}>
              Aspire Coordination has created policy and procedure documents for several businesses. We have so far concentrated on engineering and industrial firms. Our knowledgeable staff are confident in their ability to expand this service to other industrial verticals, nevertheless. In order to facilitate the daily operations of various organisational activities, we have also created a number of templates.
              </Typography>
              <Typography sx={{ textAlign: 'left' }}>
              A company's or department's policies and procedures must be designed in tandem with the business model, which necessitates extensive stakeholder input. Tech Coordination typically leads this kind of documentation endeavour and has consultants with backgrounds in quality and compliance.
              </Typography>
            </Box>
            <Box sx={{
              flex: '1 1 50%',
              minHeight: { xs: 300, md: 400 },
              backgroundImage: `url(${image2})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 2,
            }}/>
          </Box>

        
        </Box>
      </Container>
    </Box>
  )
}

export default Businessdoc