import React from 'react';
import { Box, Container, Toolbar, Typography } from '@mui/material';
import image1 from '../assets/factory-workers-with-face-masks-protected-against-corona-virus-doing-quality-control-production-factory.jpg'
import image2 from '../assets/wide-angle-shot-excavation-machines-lookout-jackerath-garzweiler-skywalk-germany.jpg'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
const Mining = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Box sx={{ bgcolor: '#ede8f5', width: '100%' }}>
      <Container maxWidth="lg">
        <Box component="main" sx={{ p: 3 }}>
          <Toolbar />
          <Typography variant='h5' sx={{ textAlign: 'center', fontWeight: 'bold', mb: 4 }}>MINING</Typography>


          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            alignItems: 'stretch',
            gap: 4,
            mb: 4
          }}>
            <Box sx={{ 
              flex: '1 1 50%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              <Typography sx={{ textAlign: 'left', marginTop: '15px', fontWeight: 'bold' }}>
            Engineering Documentation
          </Typography>
          <Typography sx={{ textAlign: 'left', marginTop: '15px' }}>
            <ul>
              <li>Engineering Reports</li>
              <li>Engineering Test Instruction</li>
              <li>Engineering Process Specification</li>
              <li>Functional Specification</li>
              <li>Standard Operating Procedure (SOP)</li>
              <li>Standard Work Practice (SWP)</li>
              <li>Safe Work Procedure (SWP)</li>
              <li>Standard Work Instruction (SWI)</li>
              <li>Service/Maintenance manuals</li>
              <li>Installation manuals</li>
              <li>Checklists (Equipment pre-start, Operation, Maintenance)</li>
            </ul>
          </Typography>
            </Box>
            <Box sx={{
              flex: '1 1 50%',
              minHeight: { xs: 300, md: 400 },
              backgroundImage: `url(${image2})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 2,
            }}/>
          </Box>

         
        </Box>
      </Container>
    </Box>
  );
};

export default Mining;