import React from 'react'
import { Box, Container, Toolbar, Typography } from '@mui/material';
import image1 from '../assets/factory-workers-with-face-masks-protected-against-corona-virus-doing-quality-control-production-factory.jpg'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react'; 
const Productdesign = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Box sx={{ bgcolor: '#ede8f5', width: '100%' }}>
      <Container maxWidth="lg">
        <Box component="main" sx={{ p: 3 }}>
          <Toolbar/>
          <Typography variant='h5' sx={{textAlign: 'center', fontWeight: 'bold', mb: 4}}>PRODUCT DESIGN</Typography>
          <Typography sx={{textAlign: 'left', marginTop: '15px'}}>
          In order to provide thorough product design and development services, our team at Aspire Coordination Australia Pty Ltd works closely with your marketing, engineering, and manufacturing teams. We help you every step of the way, from the original idea to the finished product, making sure your design is both creative and ready for the market.
          </Typography>

          <Typography sx={{textAlign: 'left', fontWeight: 'bold', marginTop: '15px'}}>Our Comprehensive Product Design Services </Typography>
              <Typography sx={{ textAlign: 'left' }}>
              We offer a full suite of services, including:
              <ul>
                <li>Idea to Prototype: Supporting you from the initial concept through full production. </li>
                <li>Multidisciplinary Integration: Merging mechanical, electrical, electronics, and programming streams to create the final product. </li>
                <li>R&D Activities: Conducting research and development to innovate and refine designs. </li>
              </ul>
              </Typography>

          {/* First Section: Image and Text */}
          <Typography sx={{textAlign: 'left', fontWeight: 'bold', marginTop: '15px'}}>Manufacturing Support</Typography>
              <Typography sx={{ textAlign: 'left' }}>
              We offer a wide range of manufacturing help, such as:
              <ul>
                <li>Engineering: Design optimisation for production. </li>
                <li>Manufacturing Sourcing and Liaison: Connecting you with local and low-cost manufacturing options. </li>
                <li>Local Manufacturing: Facilitating production within Australia.</li>
                <li>Low-Cost Manufacturing in Asia: Sourcing cost-effective manufacturing solutions in Asia.</li>
                <li>Component Sourcing: Procuring the necessary components for your product.</li>
              </ul>
              </Typography>

              <Typography sx={{textAlign: 'left', marginTop: '15px'}}>
              We at Aspire Coordination are committed to providing exceptional design services and are excited to work with you to realise your vision. We are here to help you create a fantastic product, whether that means working with your engineers and designers or acting as your in-house product designers.
          </Typography>

        </Box>
      </Container>
    </Box>
  )
}

export default Productdesign