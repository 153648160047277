import React from 'react'
import { Box, Container, Toolbar, Typography } from '@mui/material';
import image1 from '../assets/factory-workers-with-face-masks-protected-against-corona-virus-doing-quality-control-production-factory.jpg'
import image2 from '../assets/automated-car-assembly-line-plant-automotive-industry-shop-production-assembly-machines-new-car-warehouse.jpg'
import image3 from '../assets/photo-automobile-production-line-welding-car-body-modern-car-assembly-plant-auto-industry.jpg'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
const Manufacturing = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Box sx={{ bgcolor: '#ede8f5', width: '100%' }}>
      <Container maxWidth="lg">
        <Box component="main" sx={{ p: 3 }}>
          <Toolbar/>
          <Typography variant='h5' sx={{textAlign: 'center', fontWeight: 'bold', mb: 4}}>MANUFACTURING</Typography>
          <Typography sx={{textAlign: 'left', marginTop: '15px'}}>
            The Ministry of Industry states that initiatives in the six National Manufacturing Priorities—resources technology and vital minerals processing, food and beverage, medical goods, recycling and clean energy, defence, and space—will be supported by Australia's Modern Manufacturing Initiative.
          </Typography>

          <Typography sx={{textAlign: 'left', fontWeight: 'bold', marginTop: '15px'}}>Aspire Coordination's Commitment to Manufacturing</Typography>
          <Typography sx={{textAlign: 'left', marginTop: '5px', mb: 4}}>
            At Tech Coordination Australia Pty Ltd, we are dedicated to revitalizing the manufacturing industry with our extensive expertise and experience. We serve a wide range of manufacturing organisations, from small to large-scale enterprises, with a special focus on the medical products, construction, and recycling sectors.
          </Typography>

          {/* First Section: Image and Text */}
          

          {/* Second Section: Text and Image */}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            alignItems: 'stretch',
            gap: 4,
            mb: 4
          }}>
            <Box sx={{ 
              flex: '1 1 50%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              <Typography sx={{ textAlign: 'left' }}>
                We are renowned for our ability to complete projects on time, within budget, and with outstanding quality. Our team is composed of professionals with years of experience in manufacturing, giving them a deep understanding of industry practices, processes, and quality benchmarks.
              </Typography>
              <Typography sx={{ textAlign: 'left' }}>
                Fabrication drawings, a specific type of detail drawing that calls for both manufacturing experience and CAD skills, are one of our main areas of support. Typically, our fabrication drawings consist of:
                <ul>
                  <li>Material Parts Lists</li>
                  <li>Weld Details</li>
                </ul>
              </Typography>
            </Box>
            <Box sx={{
              flex: '1 1 50%',
              minHeight: { xs: 300, md: 400 },
              backgroundImage: `url(${image2})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 2,
            }}/>
          </Box>

          {/* Third Section: Image and Text */}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            alignItems: 'stretch',
            gap: 4,
            mb: 4
          }}>
            <Box sx={{
              flex: '1 1 50%',
              minHeight: { xs: 300, md: 400 },
              backgroundImage: `url(${image1})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 2,
            }}/>
            <Box sx={{ 
              flex: '1 1 50%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              <Typography sx={{ textAlign: 'left' }}>
                Our design team excels in 3D modeling, which enables them to recognize and address issues at the outset, preventing costly adjustments later in the process. We have developed fabrication designs for:
                <ul>
                  <li>Structures</li>
                  <li>Architectural Steelwork</li>
                  <li>Balustrading/Handrails</li>
                  <li>Stairs</li>
                </ul>
              </Typography>
            </Box>
          </Box>

          {/* Fourth Section: Text and Image */}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            alignItems: 'stretch',
            gap: 4
          }}>
            <Box sx={{ 
              flex: '1 1 50%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              <Typography sx={{ textAlign: 'left', fontWeight:'bold', mb: 2 }}>
                Versatility in Software Applications
              </Typography>
              <Typography sx={{ textAlign: 'left' }}>
                Our skilled engineers and drafters know how to use a variety of software tools that meet your manufacturing requirements. We have a track record of successfully overseeing design and draughting projects using programs like:
                <ul>
                  <li>AutoCAD</li>
                  <li>ArchiCAD</li>
                  <li>SolidWorks</li>
                  <li>Revit Architecture</li>
                  <li>Architectural Desktop</li>
                  <li>Microstation</li>
                  <li>3D Studio Max</li>
                </ul>
              </Typography>
            </Box>
            <Box sx={{
              flex: '1 1 50%',
              minHeight: { xs: 300, md: 400 },
              backgroundImage: `url(${image3})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 2,
            }}/>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default Manufacturing