import React from 'react'
import { Box, Typography, Toolbar, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Container } from '@mui/material';
import image1 from '../assets/agronomist-gardener-holding-organic-healthy-fresh-salad-showing-agricultural-businessman-discussing-vegetables-nutrition-hydroponics-greenhouse-plantation-concept-agriculture.jpg'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';


const standards = [
    { code: 'AS/NZS 4745', description: 'Code of the practice of handling combustible dust' },
    { code: 'AS 1324', description: 'Air filters for use in general ventilation and air conditioning' },
    { code: 'AS/NZS 1657', description: 'Platforms, Walkways, Stairways and Ladders' },
    { code: 'AS/NZS 1668', description: 'The use of ventilation and air conditioning in building fire and smoke control in building' },
];

const Agriculture = () => {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Box sx={{ bgcolor: '#ede8f5', width: '100%' }}>
      <Container maxWidth="lg">
        <Box component="main" sx={{ p: 3 }}>
          <Toolbar/>
          <Typography variant='h5' sx={{textAlign: 'center', fontWeight: 'bold', mb: 4}}>AGRICULTURE</Typography>
          <Typography sx={{textAlign: 'left', marginTop: '15px'}}>
          According to data from the Australian Department of Agriculture, the country's agriculture sector is responsible for
          <ul>
            <li>59% of water extractions (9,434 gigalitres used by agriculture in 2015–16) and 58% of land use (446 million hectares, excluding forestry production) in Australia</li>
            <li>11% of exports of goods and services, 2.2% of GDP, and 2.6% of employment during 2018–19.</li>
          </ul>
          </Typography>

          <Typography sx={{textAlign: 'left', marginTop: '5px', mb: 4}}>
          Australia’s primary agricultural crops include wheat, coarse grains like barley, oats, sorghum, maize, and triticale, as well as rice. Oilseeds such as canola, sunflowers, soybeans, and peanuts are also cultivated, along with grain legumes like lupins and chickpeas. Additionally, sugarcane, cotton, fruits, grapes, tobacco, and a variety of vegetables make up key sectors of Australia’s crop production.
          </Typography>

          

          {/* Second Section: Text and Image */}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            alignItems: 'stretch',
            gap: 4,
            mb: 4
          }}>
            <Box sx={{ 
              flex: '1 1 50%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              <Typography sx={{ textAlign: 'left' }}>
              To assist diverse steps like cleaning, storage, grading, processing, and packing, Aspire Coordination has been offering design, draughting, and documentation services to various agriculture sectors.
              </Typography>
              <Typography sx={{textAlign: 'left', fontWeight: 'bold', marginTop: '15px'}}>
              Engineering Design and Drafting in Agriculture:
              </Typography>
              <Typography sx={{ textAlign: 'left', marginTop: '15px' }}>
              Systems including material handling, storage, processing, and packing are all included in the design service. Grain storage, meal storage solution, filling/bagging station, steps ladder, walk ways, bucket elevator, drag conveyor, screw conveyor, weigh hopper, micro filling station, cyclon, spout, general exhaust design and machinery design have all been areas in which we have worked.
              </Typography>
            </Box>
            <Box sx={{
              flex: '1 1 50%',
              minHeight: { xs: 300, md: 400 },
              backgroundImage: `url(${image1})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 2,
            }}/>
          </Box>

          {/* Third Section: Image and Text */}
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center' }}>
  
  <Box sx={{ marginTop: '50px' }}>
  <Typography  sx={{ textAlign: 'center', marginBottom: '20px' }}>
  We strictly follow Australian Standards in our work. Some of the relevant standards are as below
  </Typography>
  <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell><strong>Code</strong></TableCell>
          <TableCell><strong>Description</strong></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {standards.map((standard, index) => (
          <TableRow key={index}>
            <TableCell>{standard.code}</TableCell>
            <TableCell>{standard.description}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
</Box>
</Box>

          {/* Fourth Section: Text and Image */}
          
        </Box>
      </Container>
    </Box>
    </>
  )
}

export default Agriculture
