import React from 'react'
import { Box, Container, Toolbar, Typography } from '@mui/material';
import image1 from '../assets/factory-workers-with-face-masks-protected-against-corona-virus-doing-quality-control-production-factory.jpg'

const Tender = () => {
  return (
    <Box sx={{ bgcolor: '#ede8f5', width: '100%' }}>
      <Container maxWidth="lg">
        <Box component="main" sx={{ p: 3 }}>
          <Toolbar/>
          <Typography variant='h5' sx={{textAlign: 'center', fontWeight: 'bold', mb: 4}}>TENDER SUPPORT & DOCUMENTATION</Typography>
          <Typography sx={{textAlign: 'left', marginTop: '15px'}}>
          Our speciality at Aspire Coordination Australia Pty Ltd is assisting you in obtaining contracts by offering thorough documentation and support for tenders. We are prepared to assist your bidding process since we have a wealth of expertise obtaining contracts in the Engineering Design, Infrastructure, and Energy sectors, as well as in local government and private sector bids.
          </Typography>

          <Typography sx={{textAlign: 'left', fontWeight: 'bold', marginTop: '15px'}}>Our Expertise</Typography>
          <Typography sx={{textAlign: 'left', marginTop: '15px'}}>
          To create a convincing business case, our team combines strong technical abilities with knowledge of business analysis and marketing communication. We make sure your tender sticks out to decision-makers by presenting it in the most compelling manner possible.
          </Typography>

          


          {/* First Section: Image and Text */}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            alignItems: 'stretch',
            gap: 4,
            mb: 4,
            marginTop: '15px'
          }}>
           
            <Box sx={{ 
              flex: '1 1 50%', 
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
                <Typography sx={{textAlign: 'left', fontWeight: 'bold', marginTop: '15px'}}>Flexible Tender Documentation Services</Typography>
              <Typography sx={{ textAlign: 'left', marginTop: '15px' }}>
              We offer flexible services to meet your specific needs:
              <ul>
                <li>Tender Documentation Support: We may offer this service with little direction or oversight if you need help preparing tender documents for prompt submission, guaranteeing that your bids are finished on schedule and to a high standard.</li>
                <li>Full Tendering Cycle Support: Additionally, we provide thorough assistance during the whole tendering process, freeing up your bid manager to concentrate on the more important elements of the bid. Among our offerings are:
                    <ul>
                        <li>Estimation Development</li>
                        <li>Bid Model Creation</li>
                        <li>Skillset & Infrastructure Requirement Analysis</li>
                        <li>Supporting Documentation Production</li>
                    </ul>
                </li>
                
              </ul>
              </Typography>
              <Typography sx={{ textAlign: 'left', marginTop: '5px' }}>
              By cooperating with Aspire Coordination, you ensure that every component of your offer is thoroughly prepared, enhancing your chances of getting the contract.
              </Typography>
            </Box>
          </Box> 

        
        </Box>
      </Container>
    </Box>
  )
}

export default Tender