import React from 'react'
import { Box, Container, Toolbar, Typography } from '@mui/material';
import image1 from '../assets/3d-rendering-ventilation-system.jpg'
import image2 from '../assets/3d-rendering-ventilation-system.jpg'
import image3 from '../assets/real-estate-desk-with-keys.jpg'
import image4 from '../assets/website-html-code-browser-view-printed-white-paper-closeup-view.jpg'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
const Customisation = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Box sx={{ bgcolor: '#ede8f5', width: '100%' }}>
      <Container maxWidth="lg">
        <Box component="main" sx={{ p: 3 }}>
          <Toolbar/>
          <Typography variant='h5' sx={{textAlign: 'center', fontWeight: 'bold'}}>
            ENGINEERING CUSTOMISATION TOOLS
          </Typography>
          <Typography sx={{textAlign: 'left', marginTop: '15px'}}>
            Creating engineering customisation tools, process simulation tools, and standardisation tools for engineering, manufacturing, and maintenance organisations is our area of expertise at Aspire Coordination Australia Pty Ltd. Our solutions are made to improve the quality of goods and services, shorten cycle times, and boost productivity.
          </Typography>

          

          {/* Second Section: Text and Image */}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            alignItems: 'stretch',
            gap: 4,
            mb: 4,
            marginTop: '15px'
          }}>
            <Box sx={{ 
              flex: '1 1 50%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              <Typography sx={{ textAlign: 'left', fontWeight:'bold' }}>
                Comprehensive Solutions for Complex Processes using customisation tools
              </Typography>
              <Typography sx={{ textAlign: 'left', marginTop: '15px' }}>
                A variety of intricate engineering requirements are met by our tools, such as:
                <ul>
                  <li>Engineering Processes and Calculations</li>
                  <li>Quality Check Processes</li>
                  <li>Intelligent Master Modeling</li>
                  <li>Parametric Modeling</li>
                  <li>CAD Customization</li>
                  <li>Effort Estimation</li>
                  <li>Job Progress Tracking</li>
                </ul>
              </Typography>
              <Typography sx={{ textAlign: 'left', marginTop: '15px' }}>
                We are pleased to have engineers on our team who possess a special blend of IT programming abilities and engineering knowledge, which is crucial for developing efficient engineering customisation tools. We streamline engineering and production processes while constantly putting the needs of the user first to create straightforward yet effective solutions.
              </Typography>
            </Box>
            <Box sx={{
              flex: '1 1 50%',
              minHeight: { xs: 300, md: 400 },
              backgroundImage: `url(${image2})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 2,
            }}/>
          </Box>

          {/* Third Section: Image and Text */}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            alignItems: 'stretch',
            gap: 4,
            mb: 4
          }}>
            <Box sx={{
              flex: '1 1 50%',
              minHeight: { xs: 300, md: 400 },
              backgroundImage: `url(${image3})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 2,
            }}/>
            <Box sx={{ 
              flex: '1 1 50%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              <Typography sx={{ textAlign: 'left', fontWeight:'bold' }}>
                Prototyping and User-Centric Design
              </Typography>
              <Typography sx={{ textAlign: 'left', marginTop: '15px' }}>
                To ensure that we produce the best solutions quickly, we build prototypes to show proof of concept early on. In order to optimise benefits, we concentrate on the smooth integration and efficient use of our technical customisation tools within your company, going beyond development.
                <ul>
                  <li>User Involvement</li>
                  <li>User Manuals</li>
                  <li>Training Programs</li>
                </ul>
              </Typography>
            </Box>
          </Box>

          {/* Fourth Section: Text and Image */}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            alignItems: 'stretch',
            gap: 4,
            mb: 4
          }}>
            <Box sx={{ 
              flex: '1 1 50%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              <Typography sx={{ textAlign: 'left', fontWeight:'bold' }}>
                Software Expertise
              </Typography>
              <Typography sx={{ textAlign: 'left', marginTop: '15px' }}>
                Our group is skilled in a number of programming languages and technologies, such as:
                <ul>
                  <li>VBA</li>
                  <li>Advanced Excel</li>
                  <li>HTML</li>
                  <li>Access</li>
                  <li>NX Programming</li>
                  <li>NX Checkmate</li>
                  <li>SolidWorks Programming</li>
                  <li>Various Programming Languages</li>
                </ul>
              </Typography>
            </Box>
            <Box sx={{
              flex: '1 1 50%',
              minHeight: { xs: 300, md: 400 },
              backgroundImage: `url(${image4})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 2,
            }}/>
          </Box>

          <Typography variant='h5' sx={{textAlign: 'left', fontWeight: 'bold', mb: 2}}>
            Examples of Engineering Customisation Tools Delivered
          </Typography>
          <Typography sx={{textAlign: 'left', marginTop: '15px'}}>
            <ul>
              <li>
                <Box component="span" sx={{ fontWeight: 'bold', marginRight: 1 }}>
                  Quick Estimation and Quote Tool:
                </Box>
                Quickly generates quotes by calculating timber area and associated costs using CAD data.
              </li>
              <li>
                <Box component="span" sx={{ fontWeight: 'bold', marginRight: 1 }}>
                  Proprietary Process Automation Solution:
                </Box>
                This engineering customisation tool, which was created for a train company, expedites adherence to AAR standards while cutting down on process cycle time.
              </li>
              <li>
                <Box component="span" sx={{ fontWeight: 'bold', marginRight: 1 }}>
                  CAD Customization for Intelligent Master Modeling:
                </Box>
                Incorporates material information, production regulations, and restrictions into CAD models.
              </li>
              <li>
                <Box component="span" sx={{ fontWeight: 'bold', marginRight: 1 }}>
                  Automated Quality Check of 3D Models:
                </Box>
                Makes use of NX Checkmate to guarantee design accuracy.
              </li>
              <li>
                <Box component="span" sx={{ fontWeight: 'bold', marginRight: 1 }}>
                  Multipurpose Job Status Tracker:
                </Box>
                Uses information taken from ERP systems to forecast resources, manage workflows, and report status.
              </li>
              <li>
                <Box component="span" sx={{ fontWeight: 'bold', marginRight: 1 }}>
                  Automated Report Generation and Validation:
                </Box>
                Simplifies procedures in the engineering customisation tool for Marketing Simulation.
              </li>
            </ul>
          </Typography>
        </Box>
      </Container>
    </Box>
  )
}

export default Customisation