import React from 'react'
import { Link } from 'react-router-dom';
import {Container, Card, CardContent, Typography, Box, Toolbar} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
function About() {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
    <Box sx={{ bgcolor: '#ede8f5', width: '100%' }}>

    <Container maxWidth="lg">
    <Box component="main" sx={{ p: 3, bgcolor: '#ede8f5' }}>
        <Toolbar/>
        <Typography variant='h5' sx={{textAlign: 'center', fontWeight: 'bold'}}>ABOUT US</Typography>
        <Typography sx={{textAlign: 'center', marginTop: '5px'}}>
        Aspire Coordination Australia Pty Ltd was established to deliver a full spectrum of Engineering and Project Management services tailored to various industry sectors. Operating out of Queensland, Australia, we specialize in creating and implementing complete solutions designed to meet the specific requirements of each client.
        </Typography>
        <Typography variant='h5' sx={{textAlign: 'center', fontWeight: 'bold', marginTop: '15px'}}>Our Team at Aspire Coordination Australia</Typography>
        <Typography sx={{textAlign: 'center', marginTop: '5px'}}>
        Our team is composed of highly skilled and qualified professionals who bring a wealth of experience and expertise to every project. 
        </Typography>
        <Container sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },  // 'xs' for small screens, 'sm' for larger screens
        gap: '15px',
        marginTop: '15px',
        justifyContent: 'center',  // Center the content on smaller screens
      }}>
        
        {/* Agriculture Card */}
        
          <Card sx={{
            maxWidth: 345,
          }}>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
              Engineering Excellence 
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Our team comprises highly skilled engineers, each bringing over a decade of hands-on experience across diverse industry sectors in Australia. This wealth of expertise ensures a deep understanding of Australian Standards, empowering us to deliver solutions that consistently exceed industry expectations.
              </Typography>
            </CardContent>
          </Card>
        

        {/* Manufacturing Card */}
        
          <Card sx={{
            maxWidth: 345,
          }}>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
              Project Management Expertise 
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Alongside our engineering expertise, our team at Tech Coordination Australia includes certified Project Management professionals skilled in PRINCE2 and AGILE methodologies. These specialists are adept at handling intricate projects with efficiency, flexibility and guaranteeing timely delivery.
              </Typography>
            </CardContent>
          </Card>
        

        {/* Mining Card */}
        
          <Card sx={{
            maxWidth: 345,
          }}>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
              Process Optimization Specialists 
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Our team includes Six Sigma-certified process experts who specialize in assessing workflows, pinpointing inefficiencies, and deploying effective solutions. Their skills ensure that your operations achieve peak efficiency and deliver optimal results.
              </Typography>
            </CardContent>
          </Card>

      </Container>
    </Box>
    </Container>
    </Box>
   
    </>
  )
}

export default About
