import React from 'react'
import { Box, Toolbar, Typography, Container } from '@mui/material';
import image1 from '../assets/factory-workers-with-face-masks-protected-against-corona-virus-doing-quality-control-production-factory.jpg'
import image2 from '../assets/building-structure.jpg'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
const Civil = () => {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Box component="main" sx={{ bgcolor: '#ede8f5' }}>
        <Toolbar />
        <Container maxWidth="lg" sx={{ py: 3 }}>
          <Typography variant='h5' sx={{ textAlign: 'center', fontWeight: 'bold' }}>CIVIL</Typography>
          <Typography sx={{ textAlign: 'left', marginTop: '15px' }}>
            A notable success story includes our work on operation and maintenance manuals for an infrastructure project, which earned significant recognition within the industry.
          </Typography>

          <Typography sx={{ textAlign: 'left', marginTop: '15px' }}>
            Over the years, we have developed a deep understanding of the Civil Engineering sector, particularly in Engineering Processes, Procedures, and Standards. Our engineers are well-versed in relevant Australian Standards for civil projects, such as:
            <ul>
              <li><Box component="span" sx={{ fontWeight: 'bold', marginLeft: 1, marginRight: 1 }}>
                AS/NZS 1170:
              </Box> Structural Design Actions</li>
              <li><Box component="span" sx={{ fontWeight: 'bold', marginLeft: 1, marginRight: 1 }}>
                AS/NZS 3500:
              </Box> Plumbing and Drainage Standards</li>
              <li><Box component="span" sx={{ fontWeight: 'bold', marginLeft: 1, marginRight: 1 }}>
                AS 4100:
              </Box> Steel Structures</li>
            </ul>
          </Typography>

          {/* Third Section: Image and Text */}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            alignItems: 'stretch',
            gap: 4,
            mb: 4
          }}>
            <Box sx={{
              flex: '1 1 50%',
              minHeight: { xs: 300, md: 400 },
              backgroundImage: `url(${image2})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 2,
            }}/>
            <Box sx={{ 
              flex: '1 1 50%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              <Typography sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                Engineering Design and Drafting Services
              </Typography>
              <Typography sx={{ textAlign: 'left', marginTop: '5px' }}>
                Our team has substantial experience in designing various components and systems for civil infrastructure, including:
                <ul>
                  <li>Structural Components</li>
                  <li>Drainage Systems</li>
                  <li>Utility Enclosures</li>
                  <li>Road and Pavement Designs</li>
                  <li>Concrete and Steel Structures</li>
                  <li>Stormwater Management Systems</li>
                  <li>Electrical and Mechanical Systems Integration</li>
                </ul>
              </Typography>
            </Box>
          </Box>

          

          <Typography sx={{ textAlign: 'left', marginTop: '15px' }}>
            At Aspire Coordination, we are committed to delivering high-quality engineering solutions and documentation that meet the rigorous demands of the civil engineering industry.
          </Typography>
        </Container>
      </Box>
    </>
  )
}

export default Civil