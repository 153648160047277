import React from 'react'
import { Box, Container, Toolbar, Typography,TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import image1 from './assets/charming-yellow-house-with-wooden-windows-green-grassy-garden.jpg'
import image2 from './assets/49746.jpg'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react'; 

const standards = [
    { code: 'AS 1100', description: 'Australian Standard for technical drawing including both mechanical and architectural designs. ' },
    { code: 'AS/NZS 1554', description: 'Structural Steel Welding Set' },
    { code: 'AS 1418', description: 'Cranes, Hoists and Winches' },
    { code: 'AS 1657', description: 'Fixed platform, walkways, stairways and ladders- Design, construct and installation' },
    { code: 'AS 4100', description: 'Steel Structure' },
    { code: 'AS/NZS 5131', description: 'Structural Steelwork- Fabrication and Erection' },
];


const Prefabricated = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Box sx={{ bgcolor: '#ede8f5', width: '100%' }}>
      <Container maxWidth="lg">
        <Box component="main" sx={{ p: 3 }}>
          <Toolbar/>
          <Typography variant='h5' sx={{textAlign: 'center', fontWeight: 'bold', mb: 4}}>PREFABRICATED BUILDINGS</Typography>
          <Typography sx={{textAlign: 'left', marginTop: '15px'}}>
          Our staff at Aspire Coordination Australia Pty Ltd is highly skilled in draughting and designing in a variety of fields, including prefabricated buildings. Precast buildings, container offices, prefabricated offices, prefabricated restrooms, and prefabricated residential homes are just a few of the projects in our vast design portfolio. 
          </Typography>
          <Typography sx={{textAlign: 'left', fontWeight: 'bold', marginTop: '15px'}}>Precision and Compliance</Typography>
          <Typography sx={{ textAlign: 'left', marginTop: '15px' }}>
              We accept a range of prefabricated building manufacturing techniques, such as container construction, cold-formed steel, and precast. Our designs are customised to fit your unique requirements, whether you're building entirely or partially off-site. Among the many advantages of working with Aspire Coordination are: 
              <ul>
                <li>Reduced Construction Time </li>
                <li>Maintained Quality </li>
                <li>Maximized Efficiency </li>
                <li>Increased Profitability </li>
              </ul>
              </Typography>

          {/* Second Section: Text and Image */}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            alignItems: 'stretch',
            gap: 4,
            mb: 4
          }}>
            <Box sx={{ 
              flex: '1 1 50%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              <Typography sx={{ textAlign: 'left' }}>
              Our designs constantly strive to include all necessary components, such as meticulously specified mechanical, electrical, and plumbing systems, to guarantee that your prefab unit is "ready to go." Our design offerings include: 
              <ul>
                <li>Structural Components </li>
                <li>Architectural Aspects </li>
                <li>Electrical Systems </li>
                <li>Air Conditioning </li>
                <li>Waterlines </li>
                <li>Drainage Lines </li>
              </ul>
              </Typography>
              <Typography sx={{ textAlign: 'left' }}>
              Furthermore, we offer essential information including off-site manufacturing specifications, off-site construction paperwork, and off-site fabrication drawings. Our designs include all structural and architectural aspects, and we collaborate closely with the relevant authorities to secure the required approvals so that your design is prepared for production.
              </Typography>
            </Box>
            <Box sx={{
              flex: '1 1 50%',
              minHeight: { xs: 300, md: 400 },
              backgroundImage: `url(${image1})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 2,
            }}/>
          </Box>

          {/* Third Section: Image and Text */}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            alignItems: 'stretch',
            gap: 4,
            mb: 4
          }}>
            <Box sx={{
              flex: '1 1 50%',
              minHeight: { xs: 300, md: 400 },
              backgroundImage: `url(${image2})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 2,
            }}/>
            <Box sx={{ 
              flex: '1 1 50%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              <Typography  sx={{ textAlign: 'center', marginBottom: '20px' }}>
  We strictly follow Australian Standards in our work. Some of the relevant standards are as below
  </Typography>
  <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell><strong>Code</strong></TableCell>
          <TableCell><strong>Description</strong></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {standards.map((standard, index) => (
          <TableRow key={index}>
            <TableCell>{standard.code}</TableCell>
            <TableCell>{standard.description}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
            </Box>
          </Box>

        </Box>
      </Container>
    </Box>
  )
}

export default Prefabricated