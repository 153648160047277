import React from 'react';
import { Card, CardActions, CardContent, CardMedia, Button, Typography, Container } from '@mui/material';
import { Link } from 'react-router-dom';  // Import the Link component
import image1 from '../assets/growing-organic-ecological-plants-fields-background.jpg'
import image2 from '../assets/factory-workshop-interior-machines-glass-production-background.jpg'
import image3 from '../assets/coal-moving-along-conveyor-belt.jpg'
import image4 from '../assets/medical-banner-with-doctor-wearing-coat.jpg'
import image5 from '../assets/construction-concept.jpg'
import image6 from '../assets/macro-view-clock-mechanism.jpg'
function IndustryExpertise() {
  return (
    <>
      <Typography variant='h5' sx={{ textAlign: 'center', fontWeight: 'bold', marginTop: '15px' }}>
        Our Industry Expertise
      </Typography>

      {/* Use flexDirection column on small screens and row on larger screens */}
      <Container sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },  // 'xs' for small screens, 'sm' for larger screens
        gap: '15px',
        marginTop: '15px',
        justifyContent: 'center',  // Center the content on smaller screens
      }}>
        
        {/* Agriculture Card */}
        <Link to="/industries/agriculture" style={{ textDecoration: 'none' }}>
          <Card sx={{
            maxWidth: 345,
            "&:hover": {
              color: "#9fafca",
              transition: "all 0.3s ease-in-out",
              cursor: 'pointer',
            }
          }}>
            <CardMedia
              sx={{ height: 140 }}
              image={image1}
              title="Agriculture"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Agriculture
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Agriculture is the practice of cultivating soil, growing crops, and raising animals to produce food, fiber, and other essential resources.
              </Typography>
            </CardContent>
          </Card>
        </Link>

        {/* Manufacturing Card */}
        <Link to="/industries/manufacturing" style={{ textDecoration: 'none' }}>
          <Card sx={{
            maxWidth: 345,
            "&:hover": {
              color: "#9fafca",
              transition: "all 0.3s ease-in-out",
              cursor: 'pointer',
            }
          }}>
            <CardMedia
              sx={{ height: 140 }}
              image={image2}
              title="Manufacturing"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Manufacturing
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Manufacturing is the process of transforming raw materials into finished goods through the use of machinery, labor, and technology.
              </Typography>
            </CardContent>
          </Card>
        </Link>

        {/* Mining Card */}
        <Link to="/industries/mining" style={{ textDecoration: 'none' }}>
          <Card sx={{
            maxWidth: 345,
            "&:hover": {
              color: "#9fafca",
              transition: "all 0.3s ease-in-out",
              cursor: 'pointer',
            }
          }}>
            <CardMedia
              sx={{ height: 140 }}
              image={image3}
              title="Mining"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Mining
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Mining is the extraction of valuable minerals and resources from the earth, such as metals, coal, and gemstones.
              </Typography>
            </CardContent>
          </Card>
        </Link>

      </Container>

      {/* Second Row of Cards */}
      <Container sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },  // Switch to column layout for mobile devices
        gap: '15px',
        marginTop: '15px',
        justifyContent: 'center',
      }}>

        {/* Medical Card */}
        <Link to="/industries/medical" style={{ textDecoration: 'none' }}>
          <Card sx={{
            maxWidth: 345,
            "&:hover": {
              color: "#9fafca",
              transition: "all 0.3s ease-in-out",
              cursor: 'pointer',
            }
          }}>
            <CardMedia
              sx={{ height: 140 }}
              image={image4}
              title="Medical"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Medical
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                The medical field focuses on the diagnosis, treatment, and prevention of illnesses and injuries to improve health and well-being.
              </Typography>
            </CardContent>
          </Card>
        </Link>

        {/* Civil Card */}
        <Link to="/industries/civil" style={{ textDecoration: 'none' }}>
          <Card sx={{
            maxWidth: 345,
            "&:hover": {
              color: "#9fafca",
              transition: "all 0.3s ease-in-out",
              cursor: 'pointer',
            }
          }}>
            <CardMedia
              sx={{ height: 140 }}
              image={image5}
              title="Civil"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Civil
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Civil engineering is the design, construction, and maintenance of infrastructure, including roads, bridges, buildings, and water systems.
              </Typography>
            </CardContent>
          </Card>
        </Link>

        {/* Mechanical Card */}
        <Link to="/industries/mechanical" style={{ textDecoration: 'none' }}>
          <Card sx={{
            maxWidth: 345,
            "&:hover": {
              color: "#9fafca",
              transition: "all 0.3s ease-in-out",
              cursor: 'pointer',
            }
          }}>
            <CardMedia
              sx={{ height: 140 }}
              image={image6}
              title="Mechanical"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Mechanical
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Mechanical engineering involves the design, development, and manufacturing of machines and mechanical systems.
              </Typography>
            </CardContent>
          </Card>
        </Link>

      </Container>
    </>
  );
}

export default IndustryExpertise;
