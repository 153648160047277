import { useState } from 'react';
import React from 'react'
import Choose from '../Component/Choose';
import { Container, Toolbar, Typography, Box, Grid } from '@mui/material';
import IndustryExpertise from '../Component/IndustryExpertise';
import exampleImage1 from './assets/businessman-with-bulb-made-gears.jpg'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
function Home() {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [selectedServiceIndex, setSelectedServiceIndex] = useState(0);

  const handleClick = (index) => {
    setSelectedServiceIndex(index);
  };

  return (
    <Box sx={{ bgcolor: '#ede8f5', width: '100%' }}>
      <Box sx={{ position: 'relative', width: '100%', height: '650px' }}>
            {/* Image */}
            <Box
                component="img"
                src={exampleImage1}
                alt="Engineering & IT Solutions"
                sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />

            {/* Text Overlay */}
            <Typography
    variant="h5"
    sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontWeight: 'bold',
        color: '#C99A40',
        textShadow: '0px 0px 8px rgba(0, 0, 0, 0.7)',
        textAlign: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.8)', // semi-transparent black background
        padding: '10px 20px', // padding for spacing
        borderRadius: '5px' // rounded corners
    }}
>
Technology and Innovation Services by <br/> AspireCoordination
</Typography>

        </Box>

      <Container component="main" maxWidth="lg" sx={{ p: 3, bgcolor: '#ede8f5', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Toolbar />
        <Grid container direction="column" alignItems="center" justifyContent="center">
          <Grid item>
            <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
              Technology and Innovation Services by AspireCoordination Australia Pty Ltd
            </Typography>
          </Grid>
          <Grid item sx={{ marginTop: '15px' }}>
            <Typography>
              AspireCoordination Australia Pty Ltd is a leading provider of comprehensive Engineering and IT services. Our team excels across a broad array of technology areas and functional domains. Our specialists, drawn from diverse backgrounds including Engineering, IT, Quality, Process Improvement, and Project Management, are well-versed in different industries and committed to upholding the highest quality standards, including strict adherence to Australian Standards.
            </Typography>
          </Grid>
          <Grid item>
            <IndustryExpertise />
          </Grid>
          <Grid item>
            <Choose />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Home;