import React from 'react'
import { Box, Container, Toolbar, Typography } from '@mui/material';
import image1 from './assets/cad-engineer-working-with-manufacturing-software-develop-industrial-machinery-gears-model-engineering-technical-machine-product-computer-construction-industry-development.jpg'
import image2 from './assets/side-view-mature-man-analyzing-documents-night.jpg'
import image3 from './assets/shoulder-shot-business-man-drawing-diagrams-wall-poster.jpg'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';  
const Engineeringdoc = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Box sx={{ bgcolor: '#ede8f5', width: '100%' }}>
      <Container maxWidth="lg">
        <Box component="main" sx={{ p: 3 }}>
          <Toolbar/>
          <Typography variant='h5' sx={{textAlign: 'center', fontWeight: 'bold', mb: 4}}>ENGINEERING DOCUMENTATION</Typography>
          <Typography sx={{textAlign: 'left', marginTop: '15px'}}>
          Since the beginning, one of Aspire Coordination Australia Pty Ltd's core services has been engineering and quality documentation. Our team of skilled engineers is skilled in producing a variety of engineering documents that are customised to our clients' unique requirements. We have a lot of loyal customers that appreciate our attention to detail and dedication to quality because of our prompt and adaptable service.
          </Typography>


          {/* First Section: Image and Text */}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            alignItems: 'stretch',
            gap: 4,
            mb: 4
          }}>
            <Box sx={{
              flex: '1 1 50%',
              minHeight: { xs: 300, md: 400 },
              backgroundImage: `url(${image1})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 2,
            }}/>
            <Box sx={{ 
              flex: '1 1 50%', 
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
                <Typography sx={{textAlign: 'left', fontWeight: 'bold', marginTop: '15px'}}>Comprehensive Documentation Services</Typography>
              <Typography sx={{ textAlign: 'left' }}>
              We offer cost-effective solutions that support our clients' business expansion. The full design package frequently includes our documentation services. For instance, we manage the associated engineering documentation when hired to design a product, which includes:
              <ul>
                <li>Functional Specifications</li>
                <li>Installation/Assembly Instructions</li>
                <li>Other Related Documents</li>
              </ul>
              </Typography>

              <Typography sx={{ textAlign: 'left', marginTop: '15px' }}>
              We also offer standalone documentation services, such as:
              <ul>
                <li>Process Specifications</li>
                <li>Test Instructions</li>
                <li>Engineering Reports</li>
              </ul>
              </Typography>

              <Typography sx={{ textAlign: 'left', marginTop: '15px' }}>
              In these cases, our engineering team conducts an in-depth study of the product or process as the first step.
              </Typography>
            </Box>
          </Box>

          {/* Second Section: Text and Image */}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            alignItems: 'stretch',
            gap: 4,
            mb: 4
          }}>
            <Box sx={{ 
              flex: '1 1 50%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
                <Typography sx={{textAlign: 'left', fontWeight: 'bold', marginTop: '15px'}}>Adaptable and Standard-Compliant Documentation</Typography>
              <Typography sx={{ textAlign: 'left', marginTop: '15px' }}>
              Our ability to adjust to any documentation standard guarantees interoperability with a wide range of document management systems. To ensure clear traceability of modifications, special attention is paid to engineering document numbering and revisions.
              </Typography>
              <Typography sx={{ textAlign: 'left' }}>
              Aspire Coordination can use the client's selected document management system or our own. Our team has vast experience with Enterprise Resource Planning (ERP), Material Requisition Planning (MRP), and Product Life Cycle Management (PLM) systems, and we are adept at using programs like Objective and SharePoint. Because of our experience, we can prepare and incorporate documentation into your current systems with ease, saving you time and effort.
              </Typography>

              <Typography sx={{textAlign: 'left', fontWeight: 'bold', marginTop: '15px'}}>Commitment to Quality</Typography>
              <Typography sx={{ textAlign: 'left', marginTop: '15px' }}>
              Our first concern is quality. We guarantee the highest standards in all of our deliverables by following a methodical process. In order to satisfy all client needs, we use quality tools and procedures to ensure that every document we create is reviewed by industry professionals at least once.
              </Typography>
            </Box>
            <Box sx={{
              flex: '1 1 50%',
              minHeight: { xs: 300, md: 400 },
              backgroundImage: `url(${image2})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 2,
            }}/>
          </Box>

          {/* Third Section: Image and Text */}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            alignItems: 'stretch',
            gap: 4,
            mb: 4
          }}>
            <Box sx={{
              flex: '1 1 50%',
              minHeight: { xs: 300, md: 400 },
              backgroundImage: `url(${image3})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 2,
            }}/>
            <Box sx={{ 
              flex: '1 1 50%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
                <Typography sx={{textAlign: 'left', fontWeight: 'bold', marginTop: '15px'}}>Examples of Engineering & Quality Documents</Typography>
              <Typography sx={{ textAlign: 'left' }}>
                <ul>
                  <li>Engineering Reports</li>
                  <li>Engineering Test Instructions</li>
                  <li>Engineering Process Specifications</li>
                  <li>Functional Specifications</li>
                  <li>Standard Operating Procedures (SOP)</li>
                  <li>Standard Work Practices (SWP)</li>
                  <li>Safe Work Procedures (SWP)</li>
                  <li>Standard Work Instructions (SWI)</li>
                  <li>Service/Maintenance Manuals</li>
                  <li>Installation Manuals</li>
                  <li>Checklists (Equipment Pre-Start, Operation, Maintenance)</li>
                </ul>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default Engineeringdoc